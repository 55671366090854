import * as Models from '../models/models';
import { HttpService } from './HttpService';

class GameService {

    getDetails = (id: number): Promise<Models.IGame> => {
        return HttpService.get<Models.IGame>(`/api/Games/${id}`)
            .then(result => result).catch(error => Promise.reject(error));;
    }
    getDetailsByDay = (year: number, day: number): Promise<Models.IGame> => {
        return HttpService.get<Models.IGame>(`/api/Games/year/${year}/day/${day}`)
            .then(result => result).catch(error => Promise.reject(error));;
    }

}

export default new GameService();