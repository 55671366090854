import AuthService from "./AuthService";
import { DataResponse } from "./models/DataResponse";

export class ValidationError {
    public field: string = "";
    public message: string = "";
}

export class HttpService {
    public static get = <T>(endpoint: string, headers: any = {}, exceptionHandler?: Function): Promise<T> => {
        return HttpService.fetchApi<T>(endpoint, {}, "get", headers, [], exceptionHandler).then((dr: DataResponse<T>) => dr.data!);
    }

    public static post = <T>(endpoint: string, payload: any = {}, headers: any = {}, exceptionHandler?: Function, acceptedStatusCodes: number[] = []): Promise<DataResponse<T>> => {
        return HttpService.fetchApi<T>(endpoint, payload, "post", headers, acceptedStatusCodes, exceptionHandler);
    }

    public static put = <T>(endpoint: string, payload: any = {}, headers: any = {}, exceptionHandler?: Function): Promise<DataResponse<T>> => {
        return HttpService.fetchApi<T>(endpoint, payload, "put", headers, [], exceptionHandler);
    }

    public static delete = <T>(endpoint: string, payload: any = {}, headers: any = {}, exceptionHandler?: Function): Promise<DataResponse<T>> => {
        return HttpService.fetchApi<T>(endpoint, payload, "delete", headers, [], exceptionHandler);
    }

    private static fetchApi = <T>(endPoint: string, payload: any = {}, method = "get", headers: any = {}, acceptedStatusCodes: number[] = [], exceptionHandler?: Function): Promise<DataResponse<T>> => {
        //        var accessToken = AuthService.getToken();
        //if (accessToken && !headers.Authorization) {
        //    headers.Authorization = `Bearer ${accessToken}`;
        //}
        if (!headers["Accept"])
            headers["Accept"] = "application/json";
        if (!headers["Content-Type"])
            headers["Content-Type"] = "application/json";
        var options: any = {
            method: method.toLowerCase(),
            headers: headers,
        };
        if (method.toLowerCase() !== "get")
            options.body = JSON.stringify(payload);
        const token = AuthService.getToken();
        if (token) {
            options.headers["Authorization"] = `Bearer ${token}`;
        }
        return fetch(`${endPoint}`, options)
            .then((response: Response) => {
                if (response.status === 401 && acceptedStatusCodes.indexOf(response.status) === -1) {
                    //CforpBrowserRouter.history.push(RoutingService.login(CforpBrowserRouter.history.location.pathname));
                    return { isSuccess: false, statusCode: response.status } as DataResponse<T>;
                } else if (!response.ok && response.status !== 422 && acceptedStatusCodes.indexOf(response.status) === -1) {
                    throw response;
                } else if (response.status === 422) {
                    return response.text().then(text => {
                        var dataResponse = new DataResponse<T>();
                        dataResponse.isSuccess = false;
                        dataResponse.statusCode = response.status;
                        dataResponse.validationErrors = JSON.parse(text, JsonUtilities.reviveDateTime).errors as Array<ValidationError>;
                        return dataResponse;
                    });
                }
                return response.text().then(text => {
                    var dataResponse = new DataResponse<T>();
                    dataResponse.isSuccess = response.ok;
                    dataResponse.statusCode = response.status;
                    dataResponse.data = text ? JSON.parse(text, JsonUtilities.reviveDateTime) as T : undefined;
                    return dataResponse;
                });
            })
            .catch(e => {
                if (exceptionHandler) {
                    exceptionHandler();
                }
                throw e;
            });
    };
}

class JsonUtilities {
    public static reviveDateTime(key: any, value: any): any {
        if (typeof value === 'string') {
            let a = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/.exec(value);
            if (a) {
                return new Date(a[1]);
            }
        }

        return value;
    }
}