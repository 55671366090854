import * as Models from '../models/models';
import { HttpService } from './HttpService';


export class WeatherService {

    get = (postalCode: string, date: Date): Promise<Models.IWeatherReport> => {
        return HttpService.get(`/api/Weather/${postalCode}/${(date.getTime() * 10000) + 621355968000000000}`);
    }
    getByGame = (gameId: number): Promise<Models.IWeatherReport> => {
        return HttpService.get(`/api/Weather/game/${gameId}`);
    }
}