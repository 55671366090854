import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ITurtle, ITurtleDetails, TurtleStatus } from "../../models/models";
import { handleError } from "../../services/ErrorService";
import TurtleService from "../../services/TurtleService";
import "./PlayerAdminPage.scss";
import StatsService from "../../services/StatsService";
import { PlayerEditor } from "../../components/admin/PlayerEditor";
import { RoutingService } from "../../services/RoutingService";

const encode = (name: string) => {
  return name.replaceAll(" ", "_");
};


export const PlayerAdminPage = () => {
  const [players, setPlayers] = useState<ITurtleDetails[]>([]);
  const [rawPlayers, setRawPlayers] = useState<ITurtleDetails[]>([]);
  const [player, setPlayer] = useState<ITurtleDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");

  const navigate = useNavigate();
  const urlParams = useParams();

  useEffect(() => {
    setIsLoading(true);
    TurtleService.getAll()
      .then((c) => {
        c.sort((a, b) => a.fullNameReverse.localeCompare(b.fullNameReverse));
        setPlayers(c);
        setRawPlayers(c.slice());
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if(urlParams.playerId === "0") {
      const newPlayer = { id: 0 } as ITurtleDetails;
      setPlayer(newPlayer);
      return;
    }
    if (players.length === 0 || !urlParams.playerId) return;
    setPlayer(players.find((c) => c.id === +urlParams.playerId!));
  }, [players, urlParams.playerId]);

  const renderTurtleStatus = (turtle: ITurtleDetails) => {
    switch(turtle.status) {
      case TurtleStatus.Active:
        return <div className="player-status">A</div>;
      case TurtleStatus.Inactive:
        return <div className="player-status">I</div>;
      case TurtleStatus.InactiveSlider:
        return <div className="player-status">IS</div>;
      case TurtleStatus.Slider:
        return <div className="player-status">S</div>;
      case TurtleStatus.None:
        return <div className="player-status">?</div>;
    }
  }

  const recalcPositions = async () => {
    setIsLoading(true);
    await StatsService.recalcPositions();
    setIsLoading(false);
  }

  const recalcStats = async () => {
    setIsLoading(true);
    await StatsService.recalcStats();
    setIsLoading(false);
  }

  const recalcHoleStats = async () => {
    if(!player) return;
    setIsLoading(true);
    await StatsService.recalcHoleStats(player.id);
    setIsLoading(false);
  }

  const addTurtle = () => {
    navigate(`/admin/players/0/new_player`);
  }

  const save = async (newData: ITurtleDetails) => {
    if(!player) return;

    setIsLoading(true);
    
    const newPlayer = await TurtleService.save({...player, ...newData}); 
    setIsLoading(false);
    if(newData.id === 0) {
      players.push(newPlayer.data!);
      players.sort((a,b) => a.fullNameReverse > b.fullNameReverse ? 1 : -1);
      navigate(RoutingService.admin.turtle(newPlayer.data!));
    } else {
      players[players.indexOf(player)] = newPlayer.data!;
    }
    setPlayers(players.slice());
  }

  const filter = (newValue: string) => {
    setFilterValue(newValue);
    setPlayers(rawPlayers.filter(c => c.id === player?.id || c.fullName.toLowerCase().includes(newValue.toLowerCase())));
  }

  return (
    <div className="admin-players">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="players__container">
        <div className="actions">
          <Button onClick={recalcPositions}>Recalc Positions</Button>
          <Button onClick={recalcStats}>Recalc Stats</Button>
          <Button onClick={addTurtle}>Add</Button>
          <div>
            <TextField value={filterValue || ""} onChange={(e) => filter(e.target.value)} size="small" label="Filter" variant="standard" />
          </div>
        </div>
        <List dense={true} className="players__list">
          {players.map((turtle) => (
            <ListItem key={turtle.id}>
              <ListItemButton component={Link} to={`/admin/players/${turtle.id}/${encode(turtle.fullName)}`}>
                <ListItemText primary={<span>{renderTurtleStatus(turtle)}{turtle.fullNameReverse}</span>} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {player && <PlayerEditor player={player!} recalcHoleStats={recalcHoleStats} onSave={save} /> }
    </div>
  );
};
