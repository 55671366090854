import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ITurtle, ITurtleDetails, TurtleStatus } from "../../models/models";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

export const PlayerEditor = (props: {
  player: ITurtleDetails;
  onSave: (player: ITurtleDetails) => void;
  recalcHoleStats: (player: ITurtleDetails) => void;
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [shortName, setShortName] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [status, setStatus] = useState<TurtleStatus>(TurtleStatus.None);
  const [startDate, setStartDate] = useState<Date>();

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  useEffect(() => {
    setFirstName(props.player.firstName || "");
    setLastName(props.player.lastName || "");
    setFullName(props.player.fullName || "");
    setShortName(props.player.shortName || "");
    setStatus(props.player.status || TurtleStatus.None);
    setStartDate(props.player.startDate || new Date());
  }, [props.player]);

  const getTurtleStatus = () => {
    const allKeys = Object.keys(TurtleStatus);
    const result = [];
    for (let x = 0; x < allKeys.length / 2; x++) {
      result.push({ name: allKeys[x + allKeys.length / 2], value: allKeys[x] });
    }
    return result;
  };

  const save = () => {
    //Validate state
    props.onSave({ ...props.player, firstName: firstName!, lastName: lastName!, startDate: startDate, status: status, shortName: shortName });
  };

  return (
    <div className="player__details">
      <div>
        <h3>{fullName}</h3>
        <div>
          <Button color="primary" onClick={save}>
            Save
          </Button>
          <Button onClick={() => props.recalcHoleStats(props.player)}>Recalc Hole Stats</Button>
        </div>
        <hr />
        <div className="player__details-info">
          <div className="player__row">
            <TextField
              label="First Name"
              value={firstName || ""}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <TextField
              label="Last Name"
              value={lastName || ""}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="player__row">
            <TextField
              label="Short Name"
              value={shortName || ""}
              onChange={(e) => setShortName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="player__row">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                label="Status"
                placeholder="Set Status"
                value={status || TurtleStatus.None}
                onChange={(e) => setStatus(e.target.value as TurtleStatus)}
                style={{ width: 300 }}
                autoWidth={false}
              >
                {getTurtleStatus().map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e!)}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
