import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { RoutingService } from "../services/RoutingService";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

interface Props {
  isRegistering?: boolean;
}

export const LoginPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(!props.isRegistering);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errors, setErrors] = useState({ email: "", password: "", confirmPassword: "" });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Login`;
  }, []);

  const switchToRegister = () => {
    setErrors({ email: "", password: "", confirmPassword: "" });
    setIsLoggingIn(false);
  };

  const switchToLogin = () => {
    setErrors({ email: "", password: "", confirmPassword: "" });
    setIsLoggingIn(true);
  };

  const register = (email?: string, password?: string, confirmPassword?: string) => {
    setErrorMessage("");
    setSuccessMessage("");
    const newErrors = { email: "", password: "", confirmPassword: "" };
    if (!email) {
      newErrors.email = "Required";
    }
    if (!password) {
      newErrors.password = "Required";
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Required";
    }
    if (password !== confirmPassword) {
      newErrors.password = "Passwords do not match";
    }
    setErrors(newErrors);
    if (newErrors.password || newErrors.confirmPassword || newErrors.email) {
      return;
    }
    setIsLoading(true);
    AuthService.register(email!, password!)
      .then((c) => {
        setIsLoading(false);
        if (c.isSuccess) {
          setSuccessMessage("You have registered.  Please login now, and let Dan know so he can link you to a Turtle.");
          setIsLoggingIn(true);
          return;
        } else {
          setErrorMessage(c.validationErrors.map(c=> c.message).join("\n"));
        }
      })
      .catch((error) => {
        setErrorMessage("Unable to login");
      });
  };

  const login = (email?: string, password?: string) => {
    setErrorMessage("");
    setSuccessMessage("");
    const newErrors = { email: "", password: "", confirmPassword: "" };
    if (!email) {
      newErrors.email = "Required";
    }
    if (!password) {
      newErrors.password = "Required";
    }
    setErrors(newErrors);
    if (newErrors.password || newErrors.email) {
      return;
    }
    setIsLoading(true);
    AuthService.login(email!, password!)
      .then((c) => {
        navigate(RoutingService.home);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage("Unable to login");
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (isLoggingIn) login(data.get("email")?.toString(), data.get("password")?.toString());
    else
      register(
        data.get("email")?.toString(),
        data.get("password")?.toString(),
        data.get("confirmPassword")?.toString()
      );
  };

  return (
    <div>
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password}
            />
            {!isLoggingIn && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            )}
            {/* {isLoggingIn && (
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            )} */}
            <Grid container>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              {successMessage && <Alert severity="success">{successMessage}</Alert>}
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {isLoggingIn ? "Sign In" : "Register"}
            </Button>
            <Grid container>
              <Grid item>
                {isLoggingIn && <Button onClick={switchToRegister}>{"Don't have an account? Sign Up"}</Button>}
                {!isLoggingIn && <Button onClick={switchToLogin}>{"Already have an account? Login"}</Button>}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
