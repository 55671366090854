import { Link } from "react-router-dom";
import * as Models from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { TableHeaderColumnContent } from "./common/TableHeaderColumnContent";

import { TableCell, Button, Table, TableHead, TableRow, TableBody, ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";

import "./SeasonScoresGrid.scss";

interface Props {
  season: Models.ISeasonScoringDetails;
  requestSort: (sortBy: string, isAscending: boolean) => void;
  requestOffsetCalculation: (row: Models.ITurtleSeasonScoreSummary) => void;
}

export const SeasonScoresGrid = (props: Props) => {
  const [visibleDay, setVisibleDay] = useState<number>(1);
  const [mostImprovedVisible, setMostImprovedVisible] = useState<boolean>(true);
  const [currentSortColumn, setCurrentSortColumn] = useState<string>("grossRank");
  const [currentSortIsAsc, setCurrentSortIsAsc] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<number>();
  const [showingOffsets, setShowingOffsets] = useState<boolean>(false);
  const [dayNumbers, setDayNumbers] = useState<number[]>([]);
  const [showPutts, setShowPutts] = useState<boolean>(false);
  const [showFiR, setShowFiR] = useState<boolean>(false);
  const [showHandicaps, setShowHandicaps] = useState<boolean>(false);

  useEffect(() => {
    setVisibleDayDefault();
    if (props.season) {
      const days = props.season.games.map((g) => g.dayNumber);
      days.sort();
      setDayNumbers(days);
      setShowPutts(props.season.turtleScores.some((ts) => ts.putts !== null));
      setShowFiR(props.season.turtleScores.some((ts) => ts.fairwaysInRegulation !== null));
      setShowHandicaps(props.season.turtleScores.some((ts) => ts.seasonHandicap));
    }
  }, [props.season]);

  const setVisibleDayDefault = () => {
    if (props.season) {
      setVisibleDay(0);
    }
  };

  const getDayColumnClasses = (dayNumber: number, isGross: boolean) => {
    let base = "scoreColumn day column " + (isGross ? "grossColumn" : "netColumn");
    if (dayNumber === visibleDay) {
      base += " important";
    }
    return base;
  };

  const changeDay = (day: number) => {
    setVisibleDay(day);
    //setMostImprovedVisible(false);
  };

  const changeMostImprovedVisible = () => {
    setVisibleDay(0);
    setMostImprovedVisible(true);
  };

  const renderDayHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber)
      return (
        <TableCell colSpan={2 + (showPutts ? 2 : 0) + (showFiR ? 1 : 0)} className={"totalColumn" + getDayColumnClasses(dayNumber, true)}>
          Day {dayNumber}
        </TableCell>
      );
    return undefined;
  };

  const renderDayHeaderColumns = (dayNumber: number) => {
    return (
      <>
        {renderDayGrossHeaderColumn(dayNumber)}
        {renderDayNetHeaderColumn(dayNumber)}
        {renderDayFairwayHeaderColumn(dayNumber)}
        {renderDayGreensHeaderColumn(dayNumber)}
        {renderDayPuttsHeaderColumn(dayNumber)}
      </>
    );
  };

  const renderDayGrossHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, true)}>
          <TableHeaderColumnContent
            column={"scores[" + (dayNumber - 1) + "].grossScore"}
            text="Gross"
            currentSortColumn={currentSortColumn}
            currentSortIsAsc={currentSortIsAsc}
            requestSort={requestSort}
          />
        </TableCell>
      );
    return undefined;
  };

  const renderDayFairwayHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showFiR)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          <TableHeaderColumnContent
            column={"scores[" + (dayNumber - 1) + "].fairwaysInRegulation"}
            text="FiR"
            currentSortColumn={currentSortColumn}
            currentSortIsAsc={currentSortIsAsc}
            requestSort={requestSort}
          />
        </TableCell>
      );
    return undefined;
  };

  const renderDayGreensHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showPutts)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          <TableHeaderColumnContent
            column={"scores[" + (dayNumber - 1) + "].greensInRegulation"}
            text="GiR"
            currentSortColumn={currentSortColumn}
            currentSortIsAsc={currentSortIsAsc}
            requestSort={requestSort}
          />
        </TableCell>
      );
    return undefined;
  };

  const renderDayPuttsHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showPutts)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          <TableHeaderColumnContent
            column={"scores[" + (dayNumber - 1) + "].putts"}
            text="Putts"
            currentSortColumn={currentSortColumn}
            currentSortIsAsc={currentSortIsAsc}
            requestSort={requestSort}
          />
        </TableCell>
      );
    return undefined;
  };

  const renderDayNetHeaderColumn = (dayNumber: number) => {
    if (props.season.games.length >= dayNumber)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          <TableHeaderColumnContent
            column={"scores[" + (dayNumber - 1) + "].netScore"}
            text="Net"
            currentSortColumn={currentSortColumn}
            currentSortIsAsc={currentSortIsAsc}
            requestSort={requestSort}
          />
        </TableCell>
      );
    return undefined;
  };

  const isValidDay = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    return score.scores && score.scores.length > dayNumber - 1;
  };

  const renderDayColumns = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    return (
      <>
        {renderDayGrossColumn(score, dayNumber)}
        {renderDayNetColumn(score, dayNumber)}
        {renderDayFairwayColumn(score, dayNumber)}
        {renderDayGreensColumn(score, dayNumber)}
        {renderDayPuttsColumn(score, dayNumber)}
      </>
    );
  };

  const renderDayGrossColumn = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    if (props.season.games.length >= dayNumber)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, true)}>
          {isValidDay(score, dayNumber) && (
            <Link to={RoutingService.scoreDetails(score.turtle, score.scores[dayNumber - 1].scoreId)}>{score.scores[dayNumber - 1].grossScore}</Link>
          )}
        </TableCell>
      );
    return undefined;
  };

  const renderDayFairwayColumn = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showFiR)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          {/* {isValidDay(score, dayNumber) && <>{Math.round((score.scores[dayNumber - 1].fairwaysInRegulationPercent || 0) * 100)}%</>} */}
          {isValidDay(score, dayNumber) && <>{score.scores[dayNumber - 1].fairwaysInRegulation}</>}
        </TableCell>
      );
    return undefined;
  };

  const renderDayGreensColumn = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showPutts)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          {isValidDay(score, dayNumber) && <>{Math.round(((score.scores[dayNumber - 1].greensInRegulation || 0) / 18) * 100)}%</>}
        </TableCell>
      );
    return undefined;
  };

  const renderDayPuttsColumn = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    if (props.season.games.length >= dayNumber && showPutts)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          {isValidDay(score, dayNumber) && <>{Math.round(score.scores[dayNumber - 1].putts || 0)}</>}
        </TableCell>
      );
    return undefined;
  };

  const renderDayNetColumn = (score: Models.ITurtleSeasonScoreSummary, dayNumber: number) => {
    if (props.season.games.length >= dayNumber)
      return (
        <TableCell className={getDayColumnClasses(dayNumber, false)}>
          {isValidDay(score, dayNumber) && <span>{score.scores[dayNumber - 1].netScore}</span>}
        </TableCell>
      );
    return undefined;
  };

  const requestSort = (sortBy: string) => {
    let isAscending = currentSortColumn !== sortBy ? true : !currentSortIsAsc;
    setCurrentSortColumn(sortBy);
    setCurrentSortIsAsc(isAscending);
    props.requestSort(sortBy, isAscending);
  };

  const isSelected = (row: Models.ITurtleSeasonScoreSummary) => selectedId === row.turtle.id;

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: Models.ITurtleSeasonScoreSummary): void => {
    const isUnselecting = selectedId === data.turtle.id;
    props.requestOffsetCalculation(data);
    setSelectedId(isUnselecting ? undefined : data.turtle.id);
    setShowingOffsets(!isUnselecting);
  };

  if (!props.season) return <div />;

  return (
    <div className="seasonScoresGrid">
      <div className="gridSelectors">
        <ButtonGroup variant="contained">
          <Button variant={!visibleDay ? "contained" : "text"} style={{ width: "auto", minWidth: "unset" }} size="small" onClick={() => changeDay(0)}>
            Totals
          </Button>
          {dayNumbers.map((g) => (
            <Button
              variant={visibleDay == g ? "contained" : "text"}
              key={g}
              style={{ width: "auto", minWidth: "unset" }}
              size="small"
              onClick={() => changeDay(g)}
            >
              {"Day " + g}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <Table className="scores-table" size="small">
        <TableHead>
          <TableRow className={"superHeader day column "}>
            {/* Super Header Row */}
            <TableCell colSpan={1} className="turtleColumn"></TableCell>
            <TableCell colSpan={3 + (showPutts ? 2 : 0) + (showFiR ? 1 : 0)} className="totalColumn">
              Total
            </TableCell>
            {/* <TableCell colSpan={1} className={"scoreColumn column bAndB " + (visibleDay === 0 ? "important" : "")}></TableCell>
            <TableCell colSpan={1} className={"scoreColumn column fir " + (visibleDay === 0 ? "important" : "")}></TableCell>
            <TableCell colSpan={1} className={"scoreColumn column gir " + (visibleDay === 0 ? "important" : "")}></TableCell>
            <TableCell colSpan={1} className={"scoreColumn column putts " + (visibleDay === 0 ? "important" : "")}></TableCell> */}
            {renderDayHeaderColumn(1)}
            {renderDayHeaderColumn(2)}
            {renderDayHeaderColumn(3)}
            {renderDayHeaderColumn(4)}
            {renderDayHeaderColumn(5)}
            {renderDayHeaderColumn(6)}
          </TableRow>
          <TableRow>
            <TableCell className="turtleColumn"></TableCell>
            <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
              <TableHeaderColumnContent
                column="grossRank"
                text="Gross"
                currentSortColumn={currentSortColumn}
                currentSortIsAsc={currentSortIsAsc}
                requestSort={requestSort}
              />
            </TableCell>
            <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
              <TableHeaderColumnContent
                column="netRank"
                text="Net"
                currentSortColumn={currentSortColumn}
                currentSortIsAsc={currentSortIsAsc}
                requestSort={requestSort}
              />
            </TableCell>
            <TableCell className={"scoreColumn day column bAndB " + (mostImprovedVisible && visibleDay === 0 ? "important" : "")}>
              <TableHeaderColumnContent
                column="improvement"
                text="B&amp;B"
                currentSortColumn={currentSortColumn}
                currentSortIsAsc={currentSortIsAsc}
                requestSort={requestSort}
              />
            </TableCell>
            {showFiR && (
              <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                <TableHeaderColumnContent
                  column="fairwaysInRegulation"
                  text="FiRs"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
            )}
            {showPutts && (
              <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                <TableHeaderColumnContent
                  column="greensInRegulation"
                  text="GiRs"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
            )}
            {showPutts && (
              <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                <TableHeaderColumnContent
                  column="putts"
                  text="Putts"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
            )}
            {renderDayHeaderColumns(1)}
            {renderDayHeaderColumns(2)}
            {renderDayHeaderColumns(3)}
            {renderDayHeaderColumns(4)}
            {renderDayHeaderColumns(5)}
            {renderDayHeaderColumns(6)}
          </TableRow>
        </TableHead>
        {/* //TODO:DF displayRowCheckbox={false} stripedRows={true} */}
        <TableBody className="table-body">
          {props.season.turtleScores.map((t, x) => {
            const isItemSelected = isSelected(t);
            return (
              <TableRow key={t.turtle.id} hover onClick={(event) => handleClick(event, t)} selected={isItemSelected}>
                <TableCell className="turtleColumn">
                  <span className="rank">{x + 1}.</span>
                  <Link
                    to={RoutingService.turtleSeason(t.turtle, {
                      id: props.season.id,
                      year: props.season.year,
                    })}
                  >
                    <span className="name">{t.turtle.fullNameReverse}</span>
                    <span className="shortname">{t.turtle.shortName}</span>
                  </Link>
                  { showHandicaps && <span className="handicap">{t.seasonHandicap}</span> }
                </TableCell>
                <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                  {showingOffsets ? t.grossOffset : t.grossScore}
                </TableCell>
                <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                  {showingOffsets ? t.netOffset : t.netScore}
                </TableCell>
                <TableCell className={"scoreColumn day column bAndB " + (mostImprovedVisible && visibleDay == 0 ? "important" : "")}>
                  <span title={(Math.round(t.improvement * 100000) / 1000).toString()}>{Math.round(t.improvement * 1000) / 10}%</span>
                </TableCell>
                {showFiR && (
                  <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                    {/* {Math.round((t.fairwaysInRegulationPercent || 0) * 100)}% */}
                    {t.fairwaysInRegulation}
                  </TableCell>
                )}
                {showPutts && (
                  <TableCell className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}>
                    {/* {Math.round(((t.greensInRegulation || 0) / 18) * 100)}% */}
                    { t.greensInRegulation }
                  </TableCell>
                )}
                {showPutts && (
                  <TableCell
                    className={"scoreColumn day column " + (visibleDay === 0 ? "important" : "")}
                    title={t.putts ? `Total Putts: ${t.putts}` : undefined}
                  >
                    { t.putts }
                  </TableCell>
                )}
                {renderDayColumns(t, 1)}
                {renderDayColumns(t, 2)}
                {renderDayColumns(t, 3)}
                {renderDayColumns(t, 4)}
                {renderDayColumns(t, 5)}
                {renderDayColumns(t, 6)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
