import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import SeasonService from "../services/SeasonService";
import * as Models from "../models/models";
import AuthService from "../services/AuthService";
import { GameDaySummary } from "./GameDaySummary";

import { RoutingService } from "../services/RoutingService";
import { AppLoading } from "./common/AppLoading";
import { Button } from "@mui/material";
import "../css/site.css";
import "../css/Main.scss";
interface IState {
  games: Array<Models.IGame>;
  season?: Models.ISeasonSummary;
  isC: boolean;
  isLoading: boolean;
  user?: Models.IIdentity;
}

export class Home extends React.Component<any, IState> {
  buttonStyle = {
    minWidth: 50,
  };

  constructor(props: any) {
    super(props);
    console.log("Crated home");
    this.state = { games: [], isC: true, isLoading: true };
  }

  componentDidMount() {
    document.title = "Home";
    this.setState({ user: AuthService.getIdentity() });
    SeasonService.getGames()
      .then((data) => this.setState({ isLoading: false, games: data.games, season: data.season }))
      .catch((error) => console.error(error));
  }

  private toggleTemperature = (isC: boolean) => {
    this.setState({ isC: isC });
  };

  private renderPage() {
    return (
      <div className="home">
        <h4>
          <span className="welcome-text">Welcome to the&nbsp;</span>
          {this.state.season ? (
            <Link to={RoutingService.seasonDetails(this.state.season!)}>{this.state.season!.year} Season</Link>
          ) : (
            <span>Turtle Tour</span>
          )}
          {this.state.user && <span className="user-name">&nbsp;{this.state.user.turtle?.firstName}</span>}
        </h4>
        <div className="gameDaySummaryContainer">
          {this.state.games.map((game) => (
            <GameDaySummary game={game} isC={this.state.isC} season={this.state.season!} key={game.dayNumber} />
          ))}
        </div>
        <div>
          <Button size="small" style={this.buttonStyle} onClick={() => this.toggleTemperature(true)}>
            C&deg;
          </Button>
          <Button size="small" style={this.buttonStyle} onClick={() => this.toggleTemperature(false)}>
            F&deg;
          </Button>
        </div>
      </div>
    );
  }

  public render() {
    return this.state.isLoading ? <AppLoading /> : this.renderPage();
  }
}
