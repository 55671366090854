import * as Models from '../models/models'
import { HttpService } from './HttpService';

class TurtleService {
    save = (player: Models.ITurtleDetails) => {
      return HttpService.post<Models.ITurtleDetails>(`/api/Turtles/`, player)
      .then(response => response).catch(error => Promise.reject(error));
    }

    get = (id: number): Promise<Models.ITurtle> => {
        return HttpService.get<Models.ITurtle>(`/api/Turtles/${id}`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    getAll = (): Promise<Models.ITurtleDetails[]> => {
        return HttpService.get<Models.ITurtleDetails[]>('/api/Turtles/')
            .then(response => response).catch(error => Promise.reject(error));
    }

    getWithCareerStats = (id: number): Promise<Models.ITurtleWithCareerStats> => {
        return HttpService.get<Models.ITurtleWithCareerStats>(`/api/Turtles/${id}/career`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    getSeasonScores = (turtleId: number, seasonId: number): Promise<Models.ITurtleScoreDetails> => {
        return HttpService.get<Models.ITurtleScoreDetails>(`/api/Scores/turtle/${turtleId}/season/${seasonId}`)
            .then(response => response).catch(error => Promise.reject(error));
    }
}

export default new TurtleService();