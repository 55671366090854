import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ClosestToThePinEntry } from "../../components/scoreentry/ClosestToThePinEntry";
import { GroupList } from "../../components/scoreentry/GroupList";
import { ScoreCard } from "../../components/scoreentry/ScoreCard";
import { ITurtle } from "../../models/models";
import {
  ClosestToTheHoleWinner,
  GameMessage,
  GameResponse,
  Hole,
  HoleScoreEntry,
  ScoreDetail,
} from "../../models/ScoreEntryModels";
import { handleError } from "../../services/ErrorService";
import ScoreEntryService from "../../services/ScoreEntryService";
import "./ScoreEntryPage.scss";

export const ScoreEntryPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [game, setGame] = useState<GameResponse>();
  const [day, setDay] = useState<number>();
  const [score, setScore] = useState<ScoreDetail>();
  const [scoreHoles, setScoreHoles] = useState<HoleScoreEntry[]>([]);
  const [turtle, setTurtle] = useState<ITurtle>();
  const [turtles, setTurtles] = useState<ITurtle[]>([]);
  const [messages, setMessages] = useState<GameMessage[]>([]);
  const [closestIsOpen, setClosestIsOpen] = useState<boolean>(false);
  const [closestToWinners, setClosestToWinners] = useState<ClosestToTheHoleWinner[]>([]);

  const urlParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (urlParams.day) {
      setDay(+urlParams.day);
      setIsLoading(true);
      ScoreEntryService.getGame(+urlParams.day)
        .then((game) => {
          setIsLoading(false);
          setGame(game);
          const turtles = game!.groups.flatMap((c) => c.turtles).map((c) => c.turtle);
          turtles.sort((a, b) => a.fullNameReverse.localeCompare(b.fullNameReverse));
          setTurtles(turtles);
          const closestToWinner = game.game.closestToWinners || [];
          for (let x = closestToWinner.length; x < 2; x++) {
            closestToWinner.push({} as any);
          }
          setClosestToWinners(game.game.closestToWinners.slice());
        })
        .catch((error) => {
          console.log("ERROR");
          if (error?.response?.status === 401) {
            navigate("/login");
          } else {
            handleError(error);
          }
          setIsLoading(false);
        });
    }
  }, [urlParams.day]);

  useEffect(() => {
    if (urlParams.turtleId && game) {
      setIsLoading(true);
      setTurtle(game.groups.flatMap((c) => c.turtles).find((c) => c.turtle.id === +urlParams.turtleId!)?.turtle);
      ScoreEntryService.getScore(game.game.id, +urlParams.turtleId)
        .then((score) => {
          setScoreHoles(score.holes);
          setScore(score);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          handleError(error);
        });
    } else {
      setTurtle(undefined);
      setScore(undefined);
    }
  }, [urlParams.turtleId, game]);

  useEffect(() => {
    document.title = day ? `Score Entry: Day ${day}` : "Score Entry";
    //TODO:DF isDirty?
    if (day && day.toString() !== urlParams.day) {
      navigate(`/admin/entry/${day}`);
    }
  }, [day]);

  const validateHole = (hole: HoleScoreEntry, holeInfo: Hole) => {
    hole.errors = { grossScore: undefined, putts: undefined };
    if (hole.grossScore > holeInfo.par * 2) {
      hole.errors["grossScore"] = "More than double par";
    } else if (hole.putts && hole.putts > hole.grossScore) {
      //hole.error = true;
      hole.errors["putts"] = "More Putts than Strokes";
    } else if (hole.grossScore <= 0) {
      //hole.error = true;
      hole.errors["grossScore"] = "Invalid gross score";
    } else if (hole.putts && hole.putts < 0) {
      hole.errors["putts"] = "Invalid putts";
    } else if (hole.fairwayInRegulation && holeInfo.par === 3) {
      hole.errors["firs"] = "Fairway in regulation on par 3";
    }

  };

  const onScoreValueChange = (field: string, holeId: number, value: any) => {
    if (!score) {
      return;
    }
    const hole = score.holes.find((c) => c.holeId === holeId);
    if (!hole) {
      handleError("No hole found");
      return;
    }
    const currentTurtle = game?.groups.flatMap((c) => c.turtles).find((c) => c.turtle.id === turtle?.id);
    if (!currentTurtle) {
      handleError("No turtle found");
      return;
    }
    hole[field] = value;
    const holeInfo = game?.game.holes.find((c) => c.id === hole.holeId);
    if ((field === "grossScore" || field === "putts") && hole.putts !== undefined && hole.grossScore) {
      if (holeInfo) {
        hole.greenInRegulation = holeInfo.par - 2 >= hole.grossScore - hole.putts;
      }
    }
    if (field == "grossScore" && holeInfo) {
      hole.adjustedScore = hole.grossScore;
      for (var i = 0; i <= 100; i++) {
        if (holeInfo.handicap <= currentTurtle.turtleHandicap - 18 * i) hole.adjustedScore -= 1;
        else if (currentTurtle.turtleHandicap < 0 && holeInfo.handicap > currentTurtle.turtleHandicap + 18 * (i + 1))
          hole.adjustedScore += 1;
        else i = 101;
      }
    }
    validateHole(hole, holeInfo!);
    score.holes = score.holes.slice();
    setScoreHoles(score.holes);
  };

  const save = async () => {
    if (!score) return;
    setIsLoading(true);
    const currentTurtle = game?.groups.flatMap((c) => c.turtles).find((c) => c.turtle.id === turtle?.id);
    let newScore;
    try {
      if (score?.scoreId === 0)
        newScore = await ScoreEntryService.create(currentTurtle!.turtle.id, game!.game.id, scoreHoles);
      else newScore = await ScoreEntryService.update(currentTurtle!.turtle.id, game!.game.id, scoreHoles);
      score!.scoreId = newScore.id;
      const scoreSummary = game!.groups.flatMap((c) => c.turtles).find((c) => c.turtle.id === +urlParams.turtleId!);
      scoreSummary!.scoreSummary.gross = (newScore as any).grossScore;
      setScore(score);
      if (currentTurtle) {
        currentTurtle.scoreSummary.isScoreEntered = true;
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const didNotPlay = () => {
    if (!score) return;
    score.holes.forEach((hole) => {
      const holeInfo = game?.game.holes.find((c) => c.id === hole.holeId);
      hole.grossScore = holeInfo!.par * 2;
      hole.putts = 2;
      hole.fairwayInRegulation = undefined;
    });
    score.holes = score.holes.slice();
    setScoreHoles(score.holes);
  };

  const generateMessages = async () => {
    if (!game) return;
    setIsLoading(true);
    const result = await ScoreEntryService.generateMessages(game.game.id);
    setMessages(result.messages);
    setIsLoading(false);
  };

  const completeGame = async () => {
    if (!game) return;
    setIsLoading(true);
    try {
    await ScoreEntryService.completeGame(game.game.id);
    }
    catch(error) {
      console.error(error);
      alert("Save Failed");
    }
    game.game.isComplete = true;
    setGame(game);
    setIsLoading(false);
  };

  const saveClosestChanges = async (winners: ClosestToTheHoleWinner[]) => {
    if (!game) return;
    await ScoreEntryService.saveClosestToWinners(game.game.id, winners);
    setClosestToWinners(winners);
    setClosestIsOpen(false);
  };

  return (
    <div>
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="score-entry">
        <div className="group-content">
          <div className="day-selection">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="daynumber-label">Day Number</InputLabel>
              <Select
                labelId="daynumber-label"
                label="Day Number"
                placeholder="Select Day"
                value={day || ""}
                onChange={(e) => navigate(`/admin/entry/${e.target.value}`)}
                style={{ width: 300 }}
                autoWidth={false}
              >
                {[1, 2, 3, 4, 5, 6].map((d) => (
                  <MenuItem key={d} value={d}>
                    Day {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {game && (
            <div className="group-list">
              {game.game.isComplete && <h4>Game Completed</h4>}
              <GroupList groups={game.groups} dayNumber={game.game.dayNumber} />
            </div>
          )}
        </div>
        <div className="main-content">
          {game && (
            <div className="header">
              <div className="info-text">
                {turtle && <span className="header--turtle">{turtle.fullName}</span>}
                <span className="header--course">{game.game.tee.course.name}</span>
              </div>
              <div className="commands">
                <Button size="small" variant="outlined" onClick={save}>
                  Save
                </Button>
                <Button size="small" variant="outlined" onClick={generateMessages}>
                  Generate Messages
                </Button>
                <Button size="small" variant="outlined" onClick={completeGame}>
                  Complete Game
                </Button>
                <Button size="small" variant="outlined" onClick={() => setClosestIsOpen(true)}>
                  Closest To ({closestToWinners.length})
                </Button>
                <Button size="small" variant="outlined" color="error" onClick={didNotPlay}>
                  Did Not Play
                </Button>
              </div>
            </div>
          )}
          <div className="scorecard">
            {score && game && (
              <div>
                <ScoreCard holeScores={scoreHoles} holes={game.game.holes} onValueChange={onScoreValueChange} />
                <div className="scorecard-errors">
                  {scoreHoles.map((h) => (
                    <ul key={h.holeId}>
                      {Object.getOwnPropertyNames(h.errors)
                        .filter((c) => h.errors[c])
                        .map((p) => (
                          <li key={h.holeId.toString() + p} className="scorecard-errors-error">
                            {h.errors[p]}
                          </li>
                        ))}
                    </ul>
                  ))}
                </div>
              </div>
            )}
          </div>
          {game && (
            <div className="closestTo">
              <ClosestToThePinEntry
                winners={closestToWinners}
                holes={game.game.holes}
                turtles={turtles}
                isOpen={closestIsOpen}
                onCancel={() => setClosestIsOpen(false)}
                onSave={saveClosestChanges}
              />
            </div>
          )}
          {messages && messages.length > 0 && (
            <div className="messages">
              <h4>Messages</h4>
              {messages.map((message, index) => (
                <div key={index}>{message.messageText}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
