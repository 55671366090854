import { Link } from "react-router-dom";

import { ISeasonSummary, SeasonGroups } from "../models/models";
import { RoutingService } from "../services/RoutingService";
import "./GroupsPage.scss";

import SeasonService from "../services/SeasonService";
import * as Models from "../models/models";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { handleError } from "../services/ErrorService";
import { Select, MenuItem, Button, Backdrop, CircularProgress, FormControl, Card, Divider } from "@mui/material";
import AuthService from "../services/AuthService";

export const GroupsPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seasons, setSeasons] = useState<ISeasonSummary[]>([]);
  const [season, setSeason] = useState<ISeasonSummary>();
  const [groups, setGroups] = useState<SeasonGroups>();
  const [selectedTurtleId, setSelectedTurtleId] = useState<number>();

  const navigate = useNavigate();
  const urlParams = useParams();
  useEffect(() => {
    document.title = `Groups`;
    setIsLoading(true);
    SeasonService.getSeasonSummaries().then((seasons) => {
      setSeasons(seasons);
      setIsLoading(false);
    });
    const identity = AuthService.getIdentity();
    if (identity?.turtle) {
      setSelectedTurtleId(identity.turtle?.id);
    }
  }, []);

  useEffect(() => {
    if (seasons.length > 0 && !urlParams.year) {
      navigate(`/groups/${seasons[0].year}`);
    } else if (seasons.length > 0 && urlParams.year) {
      const s = seasons.find((c) => c.year === +urlParams.year!);
      if (s) {
        document.title = `Groups: ${s.year}`;
        setSeason(s);
        setIsLoading(true);
        SeasonService.getGroups(s.year)
          .then((groups) => {
            setGroups(groups);
            setIsLoading(false);
          })
          .catch((error) => handleError(error));
      }
    }
  }, [seasons, urlParams.year]);

  const handleMenuChange = (event: any) => {
    navigate(`/groups/${event.target.value}`);
  };

  const selectTurtle = (turtle: Models.ITurtle) => {
    setSelectedTurtleId(turtle.id);
  };

  const renderYearHeader = () => {
    return (
      <div className="yearHeader">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            value={season ? season.year : ""}
            onChange={handleMenuChange}
            style={{ width: 300 }}
            autoWidth={false}
          >
            {seasons.map((year: Models.ISeasonSummary) => (
              <MenuItem key={year.id} value={year.year} className="yearContainer">
                {"Season: " + year.year.toString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const formatDate = (date?: Date): string => {
    if (!date) return "";
    let output: string = "";
    switch (date.getMonth()) {
      case 9:
        output += "Oct";
        break;
    }
    output += ` ${date.getDate()} ${date.getFullYear()} ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} AM`;

    return output;
  };

  const renderDays = () => {
    if (!groups) return <></>;
    return (
      <div className="groupsContainer">
        {groups.gameGroups.map((game) => (
          <div key={game.game.id} className="groups-day">
            <Divider>
                <span className="day">Day: {game.game.dayNumber} {formatDate(game.game.date)}</span>
                <Link className="course" to={RoutingService.course(game.game.tee.course)}>{game.game.tee.course.name}</Link>
            </Divider>
            <div className="groups-game">
              {game.groups.map((group) => (
                <Card key={group.groupNumber} className="groups-game-turtles">
                  {group.turtles
                    .filter((t) => t)
                    .map((t) => (
                      <div
                        key={t.id}
                        className={"groups-game-turtle-turtle" + (selectedTurtleId === t.id ? " turtle-selected" : "")}
                      >
                        <Button style={{ textTransform: "none" }} className="full" onClick={() => selectTurtle(t)}>
                          {t.fullName}
                        </Button>
                        <Button style={{ textTransform: "none" }} className="mobile" onClick={() => selectTurtle(t)}>
                          {t.shortName}
                        </Button>
                      </div>
                    ))}
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="GroupsList">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>Groups</h1>
      {renderYearHeader()}
      <div>{renderDays()}</div>
    </div>
  );
};
