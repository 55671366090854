import axios from "axios";
import { HttpService } from './HttpService';
import * as ScoreEntryModels from "../models/ScoreEntryModels";

class ScoreEntryService {
    getGame = async (dayNumber: number): Promise<ScoreEntryModels.GameResponse> => {
        const response = await axios.get<ScoreEntryModels.GameResponse>(`/api/ScoreEntry/Games/${dayNumber}`);
        return response.data;
        // return HttpService.get<ScoreEntryModels.GameResponse>(`/api/ScoreEntry/Games/${dayNumber}`)
        //     .then(response => response).catch(error => Promise.reject(error));
    }

    getScore = async (gameId: number, turtleId: number): Promise<ScoreEntryModels.ScoreDetail> => {
        const response = await axios.get<ScoreEntryModels.ScoreDetail>(`/api/ScoreEntry/Score/${gameId}/${turtleId}`);
        response.data.holes.forEach(h => h.errors = {grossScore: undefined, putts: undefined});
        return response.data;
        // return HttpService.get<ScoreEntryModels.ScoreDetail>(`/api/ScoreEntry/Score/${gameId}/${turtleId}`)
        //     .then(response => {
        //         response.holes.forEach(h => h.errors = {grossScore: undefined, putts: undefined});
        //         return response;
        //     }).catch(error => Promise.reject(error));
    }

    create = async (turtleId: number, gameId: number, holes: ScoreEntryModels.HoleScoreEntry[]): Promise<{id: number}> => {
        const model: ScoreEntryModels.ScoreUpdateRequest = {
            turtleId: turtleId,
            grossScores: holes.map(c=> ({value: c.grossScore, holeId: c.holeId})),
            putts: holes.every(c=> c.putts !== undefined) ? holes.map(c=> ({value: c.putts!, holeId: c.holeId})) : undefined,
            fairwaysInRegulation: holes.every(c=> c.fairwayInRegulation !== undefined) ? holes.map(c=> ({value: c.fairwayInRegulation!, holeId: c.holeId})) : undefined,
        }
        return HttpService.post<{id: number}>(`/api/ScoreEntry/update/${gameId}`, model).then(response => {
            return response.data!;
        }).catch(error => Promise.reject(error));
    }

    update = async (turtleId: number, gameId: number, holes: ScoreEntryModels.HoleScoreEntry[]) : Promise<{id: number}>=> {
        const model: ScoreEntryModels.ScoreUpdateRequest = {
            turtleId: turtleId,
            grossScores: holes.map(c=> ({value: c.grossScore, holeId: c.holeId})),
            putts: holes.every(c=> c.putts !== undefined) ? holes.map(c=> ({value: c.putts!, holeId: c.holeId})) : undefined,
            fairwaysInRegulation: holes.every(c=> c.fairwayInRegulation !== undefined) ? holes.map(c=> ({value: c.fairwayInRegulation!, holeId: c.holeId})) : undefined,
        }
        return HttpService.put<{id: number}>(`/api/ScoreEntry/update/${gameId}`, model).then(response => {
            return response.data!;
        }).catch(error => Promise.reject(error));
    }

    
    generateMessages = async (gameId: number): Promise<ScoreEntryModels.GenerateMessageResponse> => {
        return HttpService.post<ScoreEntryModels.GenerateMessageResponse>(`/api/ScoreEntry/messages/${gameId}`).then(response => {
            return response.data!;
        }).catch(error => Promise.reject(error));
    }

    completeGame = async (gameId: number): Promise<void> => {
        return HttpService.post(`/api/ScoreEntry/complete/${gameId}`).then(() => {
            return;
        }).catch(error => Promise.reject(error));
    }

    saveClosestToWinners = (gameId: number, winners: ScoreEntryModels.ClosestToTheHoleWinner[]): Promise<void> => {
        const model = winners.map(w => ({holeId: w.hole.id, turtleId: w.turtle.id, id: w.id}));
        return HttpService.post(`/api/ScoreEntry/closestToWinners/${gameId}`, model).then(() => {
            return;
        }).catch(error => Promise.reject(error));
      }
      
}

export default new ScoreEntryService();