import * as React from "react";
import { Link } from "react-router-dom";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Divider,
  BottomNavigation,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/CheckCircle";
import TurtleService from "../services/TurtleService";
import { ITurtleDetails, TurtleStatus } from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { AppLoading } from "../components/common/AppLoading";
import "../css/TurtleListPage.scss";

interface IState {
  turtles: ITurtleDetails[];
  loading: boolean;
  onlyActive: boolean;
}

export class TurtleListPage extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = { loading: true, turtles: [], onlyActive: true };
  }

  public componentDidMount() {
    document.title = `Turtles`;
    TurtleService.getAll().then((data) => {
      data.sort((a, b) => (a.fullNameReverse > b.fullNameReverse ? 1 : -1));
      this.setState({ turtles: data, loading: false });
    });
  }

  public toggleOnlyActive() {
    this.setState((previous) => {
      return {
        onlyActive: !previous.onlyActive,
      };
    });
  }

  public render() {
    if (this.state.loading) return <AppLoading />;
    return (
      <div className="TurtleList">
        <h1>Turtles</h1>
        <div className="list--legend">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={this.state.onlyActive} onChange={this.toggleOnlyActive.bind(this)} />}
              label="Only Active"
              className="list--legend--toggle"
            />
          </FormGroup>
        </div>
        {this.renderTurtleList(this.state.turtles, this.state.onlyActive)}
      </div>
    );
  }

  private renderTurtleList(turtles: ITurtleDetails[], onlyActive: boolean) {
    return (
      <div className="list">
        <div className="list--legend">
          <CheckIcon className="isActive-icon" /> <span>Is an active turtle</span>
        </div>
        <Table size="small" className="desktop">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="isActive column" title="Is Active"></TableCell>
              <TableCell className="name column" title="Name"></TableCell>
              <TableCell className="started column" title="Name">
                Started
              </TableCell>
              <TableCell className="handicap column" title="Name">
                Hdcp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {turtles
              .filter((t) => !onlyActive || (t.status === TurtleStatus.Active || t.status === TurtleStatus.Slider))
              .map((t) => (
                <TableRow key={t.id}>
                  <TableCell className="isActive column">
                     {(t.status === TurtleStatus.Active || t.status === TurtleStatus.Slider) && <CheckIcon className="isActive-icon" />}
                  </TableCell>
                  <TableCell className="name column">
                    <Link to={RoutingService.turtle(t)}>{t.fullNameReverse}</Link>
                  </TableCell>
                  <TableCell className="started column">
                    {t.startDate && <Link to={RoutingService.turtle(t)}>{t.startDate.getFullYear()}</Link>}
                  </TableCell>
                  <TableCell className="handicap column">{Math.round(t.turtleHandicap!)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
