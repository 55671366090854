import { Outlet } from "react-router";
import { NavMenu } from "./NavMenu";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

interface GameCompletedMessage {
  message: string;
  url: string;
}

export const Layout = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const [gameCompleted, setGameCompleted] = useState<GameCompletedMessage>();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder().withUrl("/hubs/updates").withAutomaticReconnect().build();
    newConnection
      .start()
      .then(() => {
        setConnection(newConnection);
        console.log(newConnection);
        newConnection.on("GameCompleted", (message, url) => {
          setGameCompleted({ message, url });
        });
      })
      .catch((error) => console.error(error));
  }, []);

  return (
      <div className="main-layout">
        <header className="nav-menu">
          <NavMenu />
        </header>
        {gameCompleted && <Alert severity="success">{gameCompleted?.message}</Alert>}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
  );
};
