interface Props {
  column: string;
  text: any;
  currentSortColumn?: string;
  currentSortIsAsc?: boolean;
  requestSort: (sortBy: string) => void;
}

export const TableHeaderColumnContent = (props: Props) => (
  <div className="column-header-content" onClick={() => props.requestSort(props.column)}>
    {props.text}
    {props.column === props.currentSortColumn && props.currentSortIsAsc ? (
      <span className="glyphicon glyphicon-arrow-up"></span>
    ) : props.column === props.currentSortColumn ? (
      <span className="glyphicon glyphicon-arrow-down"></span>
    ) : undefined}
  </div>
);
