import * as Models from '../models/models'
import { HttpService } from './HttpService';

class CourseService {
    get = (id: number): Promise<Models.ICourse> => {
        return HttpService.get<Models.ICourse>(`/api/Courses/${id}`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    getAll = (): Promise<Models.ICourse[]> => {
        return HttpService.get<Models.ICourse[]>('/api/Courses/')
            .then(response => response).catch(error => Promise.reject(error));
    }

    getAllWithLowest = (): Promise<Models.ICourseWithLowest[]> => {
        return HttpService.get<Models.ICourseWithLowest[]>('/api/Courses/WithLowest')
            .then(response => response).catch(error => Promise.reject(error));
    }

    getDetails = (id: number): Promise<Models.ICourseDetails> => {
        return HttpService.get<Models.ICourseDetails>(`/api/Courses/details/${id}`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    createDirection = (course: Models.ICourse, season?: Models.ISeasonSummary): string => {
        let to = `${course.address} ${course.city} ${course.postalCode}`.replace(/ /g, '+');
        let from = season?.hotelAddress || "";
        if (!from) {
            //No hotel address, so leave out the to.  This will just show the location of the course
            from = to; to = "";
        }
        return `https://www.google.com/maps/dir/${from || to}/${to}`;
    }

    getTees = (id: number): Promise<Models.ITeeSummary[]> => {
        return HttpService.get<Models.ITeeSummary[]>(`/api/Courses/${id}/tees`)
            .then(response => response).catch(error => Promise.reject(error));
    }

}

export default new CourseService();