import * as React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IIdentity, ITurtle, UserRoles } from "../models/models";
import AuthService from "../services/AuthService";
import HomeIcon from "@mui/icons-material/Home";
import CourseIcon from "@mui/icons-material/GolfCourse";
import CurrentSeasonIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Groups2";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import StatsIcon from "@mui/icons-material/QueryStats";
import EditIcon from "@mui/icons-material/Edit";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  { name: "Home", icon: <HomeIcon />, path: "/" },
  { name: "Turtles", icon: <PersonIcon />, path: "/turtles" },
  { name: "Season", icon: <CurrentSeasonIcon />, path: "/season" },
  { name: "Groups", icon: <GroupIcon />, path: "/groups" },
  { name: "Courses", icon: <CourseIcon />, path: "/courses" },
  { name: "Trophies", icon: <TrophyIcon />, path: "/trophies" },
  { name: "Stats", icon: <StatsIcon />, path: "/stats" },
  { name: "Admin", icon: <EditIcon />, path: "/admin", role: 1 },
];

const settings = ["Logout"];

export const NavMenu = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [turtle, setTurtle] = React.useState<ITurtle>();
  const [role, setRole] = React.useState<number>();

  const navigate = useNavigate();

  React.useEffect(() => {
    const onAuthChange = (user: IIdentity | undefined) => {
      setTurtle(user?.turtle);
      setRole(user?.role);
    };
    const turtle = AuthService.getIdentity();
    onAuthChange(turtle);
    AuthService.authChangeObservable.subscribe(onAuthChange);
    return () => {
      AuthService.authChangeObservable.unsubscribe(onAuthChange);
    };
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting?: string) => {
    if (setting === "Logout") {
      AuthService.logout();
      navigate("/login");
    }
    setAnchorElUser(null);
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TURTLES
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              className="nav-menu"
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.filter(c=> !c.role || c.role === role).map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  {page.icon}
                  <Typography component="a" href={page.path} textAlign="center">
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TURTLES
          </Typography>
          <Box sx={{ flexGrow: 1, justifyContent: "center", display: { xs: "none", md: "flex" } }}>
            {pages.filter(c=> !c.role || c.role === role).map((page) => (
              <Button key={page.name} component={Link} to={page.path} className="icon-button">
                <div>{page.icon}</div>
                <div>{page.name}</div>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {turtle && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar {...stringAvatar(turtle.fullName)} />
                </IconButton>
              </Tooltip>
            )}
            {!turtle && (
              <Tooltip title="Open settings">
                <Button component={Link} to="/login" sx={{ p: 0 }}>
                  Login
                </Button>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu()}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
