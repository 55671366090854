import { handleError } from "../services/ErrorService";
import { AppLoading } from "../components/common/AppLoading";
import SeasonService from "../services/SeasonService";
import * as Models from "../models/models";
import { SeasonScoresGrid } from "../components/SeasonScoresGrid";

import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

export const SeasonPage = () => {
  const [season, setSeason] = useState<Models.ISeasonScoringDetails>();
  const [years, setYears] = useState<Models.ISeasonSummary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();
  let { year } = useParams();

  useEffect(() => {
    const fetchYear = (id: number) => {
      setIsLoading(true);
      SeasonService.getSeasonScoringById(id)
        .then((season) => {
          setIsLoading(false);
          setSeason(season);
          document.title = `Season: ${season!.year}`;
        })
        .catch((error) => handleError(error));
    };

    document.title = `Season Details`;
    var currentSeasonPromise = year ? SeasonService.getSeasonScoringByYear(+year) : Promise.resolve(undefined);
    var yearSummaryPromise = SeasonService.getSeasonSummaries();
    Promise.all([currentSeasonPromise, yearSummaryPromise])
      .then(([season, seasons]) => {
        setIsLoading(false);
        setSeason(season);
        setYears(seasons);
        if (season) document.title = `Season: ${season!.year}`;
        if (!season) fetchYear(seasons[0].id);
      })
      .catch((error: any) => handleError(error));
  }, [year]);

  const sortData = (sortBy: string, sortAsc: boolean) => {
    console.log("Sorting by:" + sortBy);
    let sortOffset = sortAsc ? 1 : -1;
    if (season) {
      season.turtleScores.sort((c: any, d: any) =>
        getValue(sortBy, c) > getValue(sortBy, d)
          ? 1 * sortOffset
          : getValue(sortBy, c) < getValue(sortBy, d)
          ? -1 * sortOffset
          : 0
      );
      season.turtleScores = season.turtleScores.slice();
      setSeason(season);
    }
  };

  const getValue = (path: string, obj: any) => {
    const match = path.match(/scores\[(?<index>\d+)\].(?<rest>.*)/);
    let value = undefined;
    if (match) {
      const index = match!.groups!["index"];
      const rest = match!.groups!["rest"];
      value = obj["scores"][index][rest];
    } else {
      value = obj[path];
    }
    return value;
  };

  const handleMenuChange = (event: SelectChangeEvent<number>) => {
    navigate(`/season/${event.target.value}`);
  };

  const onRequestOffsetCalculation = (row: Models.ITurtleSeasonScoreSummary) => {
    if (!season) return;
    season.turtleScores.forEach((s) => {
      s.netOffset = s.netScore - row.netScore;
      s.grossOffset = s.grossScore - row.grossScore;
    });
    season.turtleScores = season.turtleScores.slice();
    setSeason(season);
  };

  const renderYearHeader = () => {
    return (
      <div className="yearHeader">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            value={season ? season.year : undefined}
            onChange={handleMenuChange}
            style={{ width: 300 }}
            autoWidth={false}
          >
            {years.map((year: Models.ISeasonSummary) => (
              <MenuItem key={year.id} value={year.year} className="yearContainer">
                Season: {year.year.toString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderYear = (s: Models.ISeasonScoringDetails) => {
    return (
      <div>
        <SeasonScoresGrid season={s} requestSort={sortData} requestOffsetCalculation={onRequestOffsetCalculation} />
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div>
        {renderYearHeader()}
        {season ? renderYear(season) : <div></div>}
        {errorMessage}
      </div>
    );
  };

  return <div className="seasonPage">{isLoading ? <AppLoading></AppLoading> : renderPage()}</div>;
};
