import { Link } from "react-router-dom";

import CourseService from "../services/CourseService";
import { ICourseWithLowest, ICourse } from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { AppLoading } from "../components/common/AppLoading";
import "../css/CourseListPage.scss";
import { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";

import MapIcon from "@mui/icons-material/Map";

export const CourseListPage = () => {
  const [courses, setCourses] = useState<ICourseWithLowest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = `Courses`;
    CourseService.getAllWithLowest().then((data) => {
      data.sort((a, b) => (a.course.name > b.course.name ? 1 : -1));
      setLoading(false);
      setCourses(data);
    });
  }, []);

  const createDirection = (course: ICourse): string => {
    let from = `${course.address} ${course.city} ${course.postalCode}`.replace(/ /g, "+");
    return `https://www.google.com/maps/dir/?api=1&origin=${from}`;
  };

  const renderCourseList = (courses: ICourseWithLowest[]) => {
    return (
      <div className="list">
        <Table size="small" className="desktop">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="map column" title="Directions"></TableCell>
              <TableCell className="name column" title="Name">
                Name
              </TableCell>
              <TableCell className="lowest column" title="Lowest Score">
                Lowest Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((c) => (
              <TableRow key={c.course.id}>
                <TableCell className="map column">
                  <IconButton title="Direction" href={createDirection(c.course)} target="_blank">
                    <MapIcon />
                  </IconButton>
                </TableCell>
                <TableCell className="name column">
                  <Link to={RoutingService.course(c.course)}>{c.course.name}</Link>
                </TableCell>
                <TableCell className="lowest column">
                  {c.lowGross.turtle.fullName} in {c.lowGross.game.season.year}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Table size="small" className="mobile">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="map column" title="Directions"></TableCell>
              <TableCell className="name column" title="Name">
                Course
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((c) => (
              <TableRow key={c.course.id}>
                <TableCell className="map column">
                  <IconButton title="Direction" href={createDirection(c.course)} target="_blank">
                    <MapIcon />
                  </IconButton>
                </TableCell>
                <TableCell className="name column">
                  <div>
                    <Link to={RoutingService.course(c.course)}>{c.course.name}</Link>
                  </div>
                  <div>
                    Lowest: {c.lowGross.turtle.fullName} in {c.lowGross.game.season.year}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  if (loading) return <AppLoading />;
  return (
    <div className="CourseList">
      <h1>Courses</h1>
      {renderCourseList(courses)}
    </div>
  );
};
