import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogActions, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { IHoleSummary, ITurtle } from "../../models/models";
import { ClosestToTheHoleWinner, Game, Hole } from "../../models/ScoreEntryModels";
import "./ClosestToThePinEntry.scss";

interface Props {
  winners: ClosestToTheHoleWinner[];
  holes: Hole[];
  turtles: ITurtle[];
  isOpen: boolean;
  onCancel: () => void;
  onSave: (winners: ClosestToTheHoleWinner[]) => void;
}

export const ClosestToThePinEntry =(props: Props) => {
  const [closestToWinners, setClosestToWinners] = useState<ClosestToTheHoleWinner[]>([]);

  useEffect(() => {
    setClosestToWinners(props.winners.slice());
  }, [props.winners]);
  
  const addWinner = () => {
    const winners = closestToWinners.slice();
    winners.push({} as any);
    setClosestToWinners(winners);
  }

  const setWinnerHole= (winner: ClosestToTheHoleWinner, holeId: number) => {
    winner.hole = props.holes.find(c=> c.id === holeId) as any;
    setClosestToWinners(closestToWinners.slice());
  }
  const setWinnerTurtle= (winner: ClosestToTheHoleWinner, turtleId: number) => {
    winner.turtle = props.turtles.find(c=> c.id === turtleId) as any;
    setClosestToWinners(closestToWinners.slice());
  }

  return    <Dialog className="closestTo" open={props.isOpen} onClose={props.onCancel}>
  <DialogTitle>Closest To The Hole</DialogTitle>
  <DialogContent className="content">
    {/* <DialogContentText>
To subscribe to this website, please enter your email address here. We
will send updates occasionally.
</DialogContentText> */}
    {closestToWinners.map((winner, index) => (
      <div className="row" key={index}>
        <FormControl sx={{ m: 1, maxWidth: 120 }} size="small">
          <InputLabel id={"hole-label" + index.toString()}>Hole</InputLabel>
          <Select
            labelId={"hole-label" + index.toString()}
            label="Hole"
            placeholder="Select Hole"
            value={winner.hole?.id || ""}
            onChange={(e) => setWinnerHole(winner, +e.target.value)}
            style={{width: 120}}
            autoWidth={false}
          >
            {props.holes
              .filter((c) => c.par === 3)
              .map((d) => (
                <MenuItem key={d.id} value={d.id}>
                  Hole {d.number}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id={"turtle-label" + index.toString()}>Turtle</InputLabel>
          <Select
            labelId={"turtle-label" + index.toString()}
            label="Turtle"
            placeholder="Select Turtle"
            value={winner.turtle?.id || ""}
            onChange={(e) => setWinnerTurtle(winner, +e.target.value)}
            autoWidth={false}
            style={{width: 200}}
          >
            {props.turtles.map((turtle) => (
              <MenuItem key={turtle.id} value={turtle.id}>
                {turtle.fullNameReverse}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    ))}
  </DialogContent>
  <DialogActions>
    <Button color="success" onClick={addWinner}>Add Hole</Button>
    <Button onClick={props.onCancel}>Cancel</Button>
    <Button color="primary" onClick={()=> props.onSave(closestToWinners)}>Save</Button>
  </DialogActions>
</Dialog>
}
