import { ValidationError } from "../HttpService";


export class DataResponse<T> {
    public data?: T;
    public isSuccess: boolean = true;
    public statusCode: number;
    public validationErrors: Array<ValidationError>;

    constructor() {
        this.validationErrors = new Array<ValidationError>();
        this.statusCode = 0;
    }

}
