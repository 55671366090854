import "./RosterAdmin.scss";
import * as Models from "../../models/models";
import { useEffect, useState } from "react";
import SeasonService from "../../services/SeasonService";
import { handleError } from "../../services/ErrorService";
import {
  Grid,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Card,
  CardHeader,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";

interface Props {
  season: Models.ISeasonSummary;
}

const not = (a: readonly Models.ITurtle[], b: readonly Models.ITurtle[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a: readonly Models.ITurtle[], b: readonly Models.ITurtle[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const RosterAdmin = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roster, setRoster] = useState<Models.ITurtle[]>([]);
  const [availableTurtles, setAvailableTurtles] = useState<Models.ITurtle[]>([]);
  const [checked, setChecked] = useState<Models.ITurtle[]>([]);

  const leftChecked = intersection(checked, availableTurtles);
  const rightChecked = intersection(checked, roster);

  const setupRosterData = (roster: Models.RosterResponse) => {
    const newRoster = roster.roster.map((c) => roster.availableTurtles.find((t) => t.id === c.id)!);
    setRoster(newRoster);
    setAvailableTurtles(roster.availableTurtles.filter((c) => newRoster.indexOf(c) === -1));
  };

  useEffect(() => {
    setIsLoading(true);
    SeasonService.getRoster(props.season.id)
      .then((roster) => {
        setupRosterData(roster);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, [props.season]);

  const handleToggle = (value: Models.ITurtle) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const setLists = (newRoster: Models.ITurtle[], newAvailable: Models.ITurtle[]) => {
    newRoster.sort((a,b) => a.fullNameReverse.localeCompare(b.fullNameReverse));
    setRoster(newRoster);
    newAvailable.sort((a,b) => a.fullNameReverse.localeCompare(b.fullNameReverse));
    setAvailableTurtles(newAvailable);
  }

  const handleAllRight = () => {
    setLists(roster.concat(availableTurtles), []);
  };

  const handleCheckedRight = () => {
    setLists(roster.concat(leftChecked), not(availableTurtles, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLists(not(roster, rightChecked), availableTurtles.concat(rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLists([], availableTurtles.concat(roster));
  };

  const saveRoster = async () => {
    setIsLoading(true);
    try {
      const r = await SeasonService.updateRoster(
        props.season.id,
        roster.map((c) => c.id)
      );
      setupRosterData(r);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const customList = (items: readonly Models.ITurtle[], showTotal: boolean) => (
    <Card sx={{ width: 400, height: 430, overflow: "auto" }}>
      <Typography sx={{ fontSize: 14, marginLeft: 10 }} color="text.secondary" gutterBottom>
        {showTotal ? `${roster.length} rostered` : ""}
      </Typography>
      <List dense component="div" role="list">
        {items.map((value: Models.ITurtle) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value.id} sx={{ height: 32 }} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.fullNameReverse} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div className="roster-admin">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button size="small" onClick={saveRoster}>
        Save Roster
      </Button>
      <Grid container className="roster" spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList(availableTurtles, false)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={availableTurtles.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={roster.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(roster, true)}</Grid>
      </Grid>
    </div>
  );
};
