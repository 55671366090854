import * as Models from '../models/models';
import { HttpService } from './HttpService';


class StatsService {
    get = (): Promise<any> => {
        return HttpService.get<Models.ICourse>(`/api/stats/`)
            .then(response => response).catch(error => Promise.reject(error));
    };

    recalcPositions = (): Promise<any> => {
        return HttpService.post<any>(`/api/admin/season/positions/`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    recalcStats = (): Promise<any> => {
        return HttpService.post<any>(`/api/admin/special/stats/`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    recalcHoleStats = (turtleId: number): Promise<any> => {
        return HttpService.post<any>(`/api/admin/special/holestats/${turtleId}`)
            .then(response => response).catch(error => Promise.reject(error));
    }
}

export default new StatsService();