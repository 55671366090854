import * as Models from '../models/models'
import { HttpService } from './HttpService';

class ScoreService {
    get = (id: number): Promise<Models.ITurtleScoreWithHoleDetails> => {
        return HttpService.get<Models.ITurtleScoreWithHoleDetails>(`/api/Scores/${id}/details`)
            .then(response => response).catch(error => Promise.reject(error));
    }

}

export default new ScoreService();