import { Backdrop, Button, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ISeasonSummary, ITrophyAward } from "../../models/models";
import { handleError } from "../../services/ErrorService";
import TrophyService from "../../services/TrophyService";

import "./TrophyAdmin.scss";

interface Props {
  season: ISeasonSummary;
}
export const TrophyAdmin = (props: Props) => {
  const [trophies, setTrophies] = useState<ITrophyAward[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    TrophyService.getAllWithCurrentWinnersForSeason(props.season.id).then((c) => {
      setTrophies(c.trophies);
      setIsLoading(false);
    });
  }, [props.season]);

  const calculateWinners = async () => {
    setIsLoading(true);
    try {
      await TrophyService.calculateWinners(props.season.id);
      const t = await TrophyService.getAllWithCurrentWinnersForSeason(props.season.id);
      setTrophies(t.trophies);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="trophy-admin">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button size="small" onClick={calculateWinners}>
        Calculate Winners
      </Button>
      <div className="trophies">
        {trophies.map((trophy) => (
          <Card variant="outlined" className="trophy" key={trophy.trophy.id}>
            <CardContent className="trophy--name">
              <Typography variant="h5" component="div">
                {trophy.trophy.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <div className="trophy--winner">{trophy.winners && trophy.winners.length > 0 ? trophy.winners.map(c => c.turtle.fullName).join(", ") : "Not Awarded"}</div>
              </Typography>
              <Typography variant="body2">
                <div className="trophy--notes">{trophy.notes}</div>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
