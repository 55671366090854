import { Link } from "react-router-dom";

import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Divider, BottomNavigation } from "@mui/material";

import CourseService from "../services/CourseService";
import {
  IStats,
  IScoreSummary,
  IGamesPlayed,
  IJekyllAndHydeChampion as IJekylAndHydeChampion,
  IHoleStat,
  IStatTotals,
  IHoleScoreStat,
} from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { AppLoading } from "../components/common/AppLoading";
import "../css/StatsPage.scss";
import StatsService from "../services/StatsService";
import { Component } from "react";

interface IState {
  stats?: IStats;
  loading: boolean;
}

export class StatsPage extends Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = { loading: true };
  }

  public componentDidMount() {
    document.title = `Stats`;
    StatsService.get().then((data) => {
      this.setState({ stats: data, loading: false });
    });
  }

  private renderTotals = (totals: IStatTotals) => {
    return (
      <div className="stats-grouping">
        <h4>Totals</h4>
        <div className="text">
          <span className="number">{totals.seasons}</span> seasons,&nbsp; 
          <span className="number">{totals.gamesPlayed.toLocaleString()}</span> games, a total of&nbsp;
          <span className="number">{totals.holesPlayed.toLocaleString()}</span> holes played by&nbsp;
          <span className="number">{totals.turtles}</span> turtles hitting{" "}
          <span className="number">{totals.strokes.toLocaleString()}</span> shots.
        </div>
      </div>
    );
  };

  private renderLowestGrossScores = (scores: IScoreSummary[]) => {
    return (
      <div className="lowest-scores stats-grouping">
        <h4>Lowest Gross Score</h4>
        {scores.map((score) => (
          <div key={score.id}>
            <Link to={RoutingService.turtle(score.turtle)}>{score.turtle.fullName}</Link> shot{" "}
            <Link to={RoutingService.scoreDetails(score.turtle, score.id)}>{score.grossScore}</Link> at{" "}
            <Link to={RoutingService.course(score.game.tee.course)}>{score.game.tee.course.name}</Link> in{" "}
            <Link to={RoutingService.seasonDetails(score.game.season)}>{score.game.season.year}</Link>
          </div>
        ))}
      </div>
    );
  };

  private renderVeterans = (gamesPlayed: IGamesPlayed[]) => {
    return (
      <div className="veterans stats-grouping">
        <h4>Veterans</h4>
        {gamesPlayed.map((gp) => (
          <div key={gp.turtle.id}>
            <Link to={RoutingService.turtle(gp.turtle)}>{gp.turtle.fullName}</Link>: {gp.count}
          </div>
        ))}
      </div>
    );
  };

  private renderJekyllAndHyde = (jekyllAndHyde: IJekylAndHydeChampion) => {
    return (
      <div className="jekyll stats-grouping">
        <h4>Dr. Jekyll &amp; Mr. Hyde Grand Champion</h4>
        <div>
          <Link to={RoutingService.turtle(jekyllAndHyde.turtle)}>{jekyllAndHyde.turtle.fullName}</Link>
          &nbsp;shot{" "}
          <Link to={RoutingService.scoreDetails(jekyllAndHyde.lowScore.turtle, jekyllAndHyde.lowScore.id)}>
            {jekyllAndHyde.lowScore.grossScore}
          </Link>
          &nbsp;on{" "}
          <Link
            to={RoutingService.gameDetailsByDayNumber(
              jekyllAndHyde.lowScore.game.dayNumber,
              jekyllAndHyde.lowScore.game.season
            )}
          >
            Day {jekyllAndHyde.lowScore.game.dayNumber}
          </Link>
          &nbsp;of{" "}
          <Link to={RoutingService.seasonDetails(jekyllAndHyde.lowScore.game.season)}>
            {jekyllAndHyde.lowScore.game.season.year}
          </Link>
          &nbsp;but ... also a{" "}
          <Link to={RoutingService.scoreDetails(jekyllAndHyde.turtle, jekyllAndHyde.highScore.id)}>
            {jekyllAndHyde.highScore.grossScore}
          </Link>
          &nbsp;on{" "}
          <Link
            to={RoutingService.gameDetailsByDayNumber(
              jekyllAndHyde.highScore.game.dayNumber,
              jekyllAndHyde.highScore.game.season
            )}
          >
            Day {jekyllAndHyde.highScore.game.dayNumber}
          </Link>
        </div>
      </div>
    );
  };

  private renderHoleStatLines = (stats: IHoleStat[]) => {
    return stats.map((h) => (
      <div key={h.turtle.id}>
        <Link to={RoutingService.turtle(h.turtle)}>{h.turtle.fullName}</Link> in{" "}
        {h.scores.map((g) => (
          <Link key={g.id} to={RoutingService.gameDetails(g.game)}>
            {g.game.season.year.toString() + " "}
          </Link>
        ))}
      </div>
    ));
  };
  private renderHoleInOnes = (stats: IHoleStat[]) => {
    return (
      <div className="holeInOnes stats-grouping">
        <h4>Hole In Ones</h4>
        <div>
          {this.renderHoleStatLines(stats)}
          <div>* Al Crawford before recorded time</div>
        </div>
      </div>
    );
  };

  private renderEagles = (stats: IHoleStat[]) => {
    return (
      <div className="eagles stats-grouping">
        <h4>Eagles</h4>
        <div>{this.renderHoleStatLines(stats)}</div>
      </div>
    );
  };
  private renderPar6s = (stats: IHoleScoreStat[]) => {
    return (
      <div className="par6s stats-grouping">
        <h4>The Big Boys</h4>
        <div>
          {stats.map((stat) => (
            <div key={stat.turtle.id} className="par6s--entry">
              {stat.scores.map((score) => (
                <div key={score.game.id} className="text">
                  <Link to={RoutingService.turtle(stat.turtle)}>{stat.turtle.fullName}</Link> shot a <span className="number">{score.grossScore}</span>{" "}
                  on the {score.game.tee.course.name} par {score.hole.par} {score.hole.number}th in{" "}
                  <Link to={RoutingService.seasonDetails(score.game.season)}>{score.game.season.year}</Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  public renderStats = (stats?: IStats) => {
    if (!stats) return <div />;
    return (
      <div>
        {this.renderTotals(stats!.totals)}
        {this.renderLowestGrossScores(stats!.lowestGrossScores)}
        {this.renderVeterans(stats!.mostGamesPlayed)}
        {/* {this.renderJekyllAndHyde(stats!.jekyllAndHydeChampion)} */}
        {this.renderHoleInOnes(stats!.holeInOnes)}
        {this.renderEagles(stats!.eagles)}
        {this.renderPar6s(stats!.par6s)}
      </div>
    );
  };

  public render() {
    if (this.state.loading) return <AppLoading />;
    return (
      <div className="stats">
        <h1>Stats</h1>

        {this.renderStats(this.state.stats)}
      </div>
    );
  }
}
