import * as Models from "../models/models";
import * as HttpService from "./HttpService";
import Cookies from 'universal-cookie';
import { DataResponse } from "./models/DataResponse";
import { NotifiableObservable, Observable } from "./Observable";


interface ILoginResult {
    id: string;
    username: string;
    token: string;
}
class AuthService {
    private token: string | undefined;
    private currentIdentity: Models.IIdentity | undefined;
    private observable = new NotifiableObservable<Models.IIdentity | undefined>();
    get authChangeObservable(): Observable<Models.IIdentity | undefined> { return this.observable; }

    public login = (username: string, password: string): Promise<DataResponse<ILoginResult>> => {
        return HttpService.HttpService.post<ILoginResult>(`/api/Users/Authenticate`, { username: username, password: password })
            .then(result => {
                this.setToken(result.data!.token, result.data!);
                return result;
            }).catch(error => Promise.reject(error));
    }

    public logout = () => {
        var cookies = new Cookies();
        cookies.remove("token");
        this.observable.notify(undefined);
    }

    public register = (username: string, password: string): Promise<DataResponse<Models.IUser>> => {
        return HttpService.HttpService.post<Models.IUser>(`/api/Users/Register`, { username: username, password: password })
            .then(result => result).catch(error => Promise.reject(error));
    }

    public isAuthenticated = (): boolean => {
        return !!this.getToken();
    }

    public getToken = (): string | undefined => {
        var cookies = new Cookies();
        if (!this.token) {
            var cookie = <any>cookies.get('token');
            this.token = cookie ? cookie.token as string : undefined;
        }
        return this.token;
    }

    public getIdentity = (): Models.IIdentity | undefined => {
        if (!this.currentIdentity) {

            var cookies = new Cookies();
            var tokenCookie = <any>cookies.get("token");
            if (!tokenCookie)
                return undefined;
            var identity = tokenCookie.user as Models.IIdentity;
            this.currentIdentity = identity;
        }
        return this.currentIdentity;
    }

    private setToken = (token: string, user: Models.IIdentity, persist: boolean = true) => {
        if (persist) {
            var cookies = new Cookies();
            cookies.set('token', { token: token, user: user }, { path: '/', secure: false, expires: new Date(Date.now()+2592000), maxAge: 2592000 });
        }
        this.token = token;
        this.currentIdentity = user;
        this.observable.notify(user);
    }
}

export default new AuthService();