import { Link, useRouteError } from "react-router-dom";
import "./ErrorPage.scss";

const NotFound = () => {
  return (
    <div className="error-page">
      <div>
        <h3>Page Not Found</h3>
        <p>
          Don't spend 15 minutes looking for this page, like you did your ball. Like your ball, it is gone and you will
          never find it.
        </p>
        <Link to="/">Back Home</Link>
      </div>
    </div>
  );
};

const UnknownError = () => {
  return (
    <div className="error-page">
      <div>
        <h3>Unexpected Error</h3>
        <p>
          Much like that long putt you made, we did not expect this to happen.  Feel free to send Dan the page link and he will look into it.
        </p>
        <Link to="/">Back Home</Link>
      </div>
    </div>
  );
};

export const ErrorPage = () => {
  const error: any = useRouteError();
  return error && error.status === 404 ? <NotFound /> : <UnknownError />;
};
