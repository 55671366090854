import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Groups2";
import CourseIcon from "@mui/icons-material/GolfCourse";
import CalendarIcon from "@mui/icons-material/CalendarMonth";

import "./AdminPage.scss";
import { useEffect } from "react";

export const AdminPage = () => {
  useEffect(() => {
    document.title = "Admin";
  },[]);
  
  return (
    <div className="admin-content">
      <List>
        <ListItem>
          <ListItemButton component={Link} to="/admin/entry">
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Score Entry" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton  component={Link} to="/admin/players">
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Players" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton  component={Link} to="/admin/courses">
            <ListItemIcon>
              <CourseIcon />
            </ListItemIcon>
            <ListItemText primary="Courses" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton  component={Link} to="/admin/seasons">
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Seasons" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};
