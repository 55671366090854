import axios, { AxiosRequestConfig } from "axios";
import AuthService from "./AuthService";

axios.interceptors.request.use(function (config: AxiosRequestConfig) {
  const token = AuthService.getToken();
  if (token) {
    config.headers!["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
