
import { RoutingService } from '../services/RoutingService';
import { AppLoading } from '../components/common/AppLoading';
import '../css/TrophyListPage.scss';
import TrophyService from '../services/TrophyService';
import { ITrophyAward } from '../models/models';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { Component } from 'react';

interface IState {
    trophies: ITrophyAward[];
    loading: boolean;
}


export class TrophyListPage extends Component<{}, IState> {

    constructor(props: {}) {
        super(props);
        this.state = { loading: true, trophies: [] };
    }

    public componentDidMount() {
        document.title = `Trophys`;
        TrophyService.getAllWithCurrentWinners()
            .then(data => {
                this.setState({ trophies: data, loading: false });
            });
    }
 
    private renderTrophyList = (trophies: ITrophyAward[]) => {
        if(!trophies) return <div/>;
        return <div className="trophy-list">
            {trophies.map(t =>
                <Card className="trophy-list--card" key={t.id}>
                    <CardHeader className="trophy-list--card--header"
                        title={t.trophy.name}
                        subtitle={t.winners.map(c => c.turtle.fullName).join(", ")}
                        actAsExpander={false}
                        showExpandableButton={false} />
                    <CardContent className="trophy-list--card--text">
                        <div>{t.season.year} {t.winners.map(c => c.turtle.fullName).join(", ")}</div>
                        <div className="notes">{t.notes}</div>
                    </CardContent>
                    <CardActions className="trophy-list--card--actions">
                        <a href={RoutingService.trophyDetails(t)} >View History</a> {/* TODO:DF this was a button before */}
                    </CardActions>
                </Card>
            )}
        </div>;
    }

    public render() {
        if (this.state.loading)
            return <AppLoading />;
        return <div className="TrophyList">
            <h1>Trophies</h1>
            {this.renderTrophyList(this.state.trophies)}
        </div>;
    }



}
