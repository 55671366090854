import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { CourseDetailsPage } from "./pages/CourseDetailsPage";
import { CourseListPage } from "./pages/CourseListPage";
import { GameDetailsPage } from "./pages/GameDetailsPage";
import { LoginPage } from "./pages/LoginPage";
import { ScoreDetailsPage } from "./pages/ScoreDetailsPage";
import { SeasonPage } from "./pages/SeasonPage";
import { StatsPage } from "./pages/StatsPage";
import { TrophyDetailsPage } from "./pages/TrophyDetailsPage";
import { TrophyListPage } from "./pages/TrophyListPage";
import { TurtleListPage } from "./pages/TurtleListPage";
import { Turtle } from "./pages/TurtlePage";
import { Layout } from "./components/Layout";
import { ErrorPage } from "./pages/ErrorPage";
import { ScoreEntryPage } from "./pages/admin/ScoreEntryPage";
import "./services/AxiosInterceptors";
import { GroupsPage } from "./pages/GroupsPage";
import { AdminPage } from "./pages/admin/AdminPage";
import { SeasonAdminPage } from "./pages/admin/SeasonAdminPage";
import { PlayerAdminPage } from "./pages/admin/PlayerAdminPage";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const router = createBrowserRouter([
  // { path: "", element: <ScrollToTop /> },
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/login", element: <LoginPage /> },
      { path: "/register", element: <LoginPage isRegistering={true} /> },
      { path: "/turtles", element: <TurtleListPage /> },
      { path: "/turtle/:name/:id/:year", element: <Turtle /> },
      { path: "/turtle/:name/:id/", element: <Turtle /> },
      { path: "/score/:name/:id", element: <ScoreDetailsPage /> },
      { path: "/season/:year", element: <SeasonPage /> },
      { path: "/season", element: <SeasonPage /> },
      { path: "/groups", element: <GroupsPage /> },
      { path: "/groups/:year", element: <GroupsPage /> },
      { path: "/game/:year/Day-:day/", element: <GameDetailsPage /> },
      { path: "/trophies", element: <TrophyListPage /> },
      { path: "/trophy/:name/:id", element: <TrophyDetailsPage /> },
      { path: "/courses/", element: <CourseListPage /> },
      { path: "/course/:name/:id", element: <CourseDetailsPage /> },
      { path: "/stats", element: <StatsPage /> },
      { path: "/admin", element: <AdminPage /> },
      { path: "/admin/entry", element: <ScoreEntryPage /> },
      { path: "/admin/entry/:day", element: <ScoreEntryPage /> },
      { path: "/admin/entry/:day/:turtleId", element: <ScoreEntryPage /> },
      { path: "/admin/seasons", element: <SeasonAdminPage /> },
      { path: "/admin/seasons/:year", element: <SeasonAdminPage /> },
      { path: "/admin/players", element: <PlayerAdminPage /> },
      { path: "/admin/players/:playerId/:name", element: <PlayerAdminPage /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
