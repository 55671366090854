import { Link, useParams } from "react-router-dom";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Divider, BottomNavigation } from "@mui/material";

import { TableHeaderColumnContent } from "../components/common/TableHeaderColumnContent";
import GameService from "../services/GameService";
import { AppLoading } from "../components/common/AppLoading";

import * as Models from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { IScoreSummary } from "../models/models";

import "../css/GameDetailsPage.scss";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
interface IState {
  isLoading: boolean;
  game?: Models.IGame;
  currentSortColumn: string;
  currentSortIsAsc: boolean;
  selectedNavigationIndex: number;
}

export const GameDetailsPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSortColumn, setCurrentSortColumn] = useState<string>("grossScore");
  const [currentSortIsAsc, setCurrentSortIsAsc] = useState<boolean>(false);
  const [selectedNavigationIndex, setSelectedNavigationIndex] = useState<number>(0);
  const [game, setGame] = useState<Models.IGame>();
  const messagesRef = useRef<HTMLDivElement>(null);
  const scoresRef = useRef<HTMLDivElement>(null);
  let { year, day } = useParams();

  const handleError = (error: any) => {
    alert("Error");
    console.error(error);
  };

  useEffect(() => {
    document.title = `Game Details`;
    if (!year || !day) {
      return;
    }
    setIsLoading(true);
    GameService.getDetailsByDay(+year, +day)
      .then((game) => {
        setIsLoading(false);
        setGame(game);
        document.title = `Game: ${game.season.year} Day ${game.dayNumber}`;
        requestSort("grossScore");
      })
      .catch((error) => handleError(error));
  }, [year, day]);

  const requestSort = useCallback(
    (sortBy: string) => {
      if (!game) {
        return;
      }
      const getTieBreakerValue = (a: IScoreSummary, b: IScoreSummary) => {
        return a.netScore > b.netScore ? 1 : a.netScore < b.netScore ? -1 : 0;
      };
      let isAscending = currentSortColumn !== sortBy ? true : !currentSortIsAsc;
      console.log(`Sorting by ${sortBy} ascending:${isAscending}`);
      game!.scores.sort((a: any, b: any) => {
        let value = a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : getTieBreakerValue(a, b);
        if (!isAscending) value = value * -1;
        return value;
      });
      game.scores = game.scores.slice();
      setCurrentSortColumn(sortBy);
      setCurrentSortIsAsc(isAscending);
      setGame(game);
    },
    [currentSortIsAsc, currentSortColumn, game]
  );

  const goToSummary = () => {
    setSelectedNavigationIndex(0);
    scrollToTop(0);
  };

  const goToScores = () => {
    setSelectedNavigationIndex(1);
    scrollToRef(scoresRef);
  };

  const goToMessages = () => {
    setSelectedNavigationIndex(2);
    scrollToRef(messagesRef);
  };

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    scrollToTop(ref.current!.offsetTop);
  };

  const scrollToTop = (top: number) => {
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  };

  const renderPage = () => {
    if (!game) return <div />;
    return (
      <div>
        <div className="infoContainer">
          <h4>
            {game.season.year} Day {game.dayNumber} Details
          </h4>
          <div className="infoContainer--rightAlign">
            <div className="infoContainer--label">Course:</div>
            <div className="infoContainer--data">
              <Link to={RoutingService.course(game.tee.course)}>{game.tee.course.name}</Link>
              <div>
                ({game.tee.name} {game.tee.rating}/{game.tee.slope})
              </div>
            </div>
          </div>
          <div className="scoreSummaryContainer">
            <div>
              <span className="infoContainer--label">Low Gross:</span>
              <span className="infoContainer--data">
                <Link to={RoutingService.turtle(game.lowGrossScore.turtle)}>{game.lowGrossScore.turtle.fullName}</Link>
                <Link to={RoutingService.scoreDetails(game.lowGrossScore.turtle, game.lowGrossScore.id)}>
                  {game.lowGrossScore.grossScore}
                </Link>
              </span>
            </div>
            <div>
              <span className="infoContainer--label">Low Net:</span>
              <span className="infoContainer--data">
                <Link to={RoutingService.turtle(game.lowNetScore.turtle)}>{game.lowNetScore.turtle.fullName}</Link>
                <Link to={RoutingService.scoreDetails(game.lowNetScore.turtle, game.lowNetScore.id)}>
                  {game.lowNetScore.netScore}
                </Link>
              </span>
            </div>
            <div>
              <span className="infoContainer--label">Average:</span>
              <span className="infoContainer--data">{game.averageGross}</span>
            </div>
            <div className="infoContainer--rightAlign">
              <div className="infoContainer--label">Closest-To:</div>
              <div className="infoContainer--data long">
                {game.closestToHoleWinners.map((c) => (
                  <div key={c.id}>
                    <Link to={RoutingService.turtle(c.turtle)}>{c.turtle.fullName}</Link> on #{c.hole.number}&nbsp;
                    {c.hole.distance} yards.
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider style={{ height: 2, marginTop: 8 }} />
          <div className="scoreAndMessageContainer">
            <div className="scoreContainer" ref={scoresRef}>
              <h4>Scores</h4>
              <Table size="small" style={{ maxWidth: 400 }}>
                {/* TODO:DF fixed header */}
                <TableHead>
                  <TableRow>
                    <TableCell className="column" title="Turtle Name" style={{ width: 195 }}>
                      Name
                    </TableCell>
                    <TableCell className="scoreColumn column sortable" title="Gross" style={{ width: 45, paddingLeft: 12 }}>
                      <TableHeaderColumnContent
                        column="grossScore"
                        text="Gross"
                        currentSortColumn={currentSortColumn}
                        currentSortIsAsc={currentSortIsAsc}
                        requestSort={requestSort}
                      />
                    </TableCell>
                    <TableCell className="scoreColumn column sortable" title="Total Net" style={{ width: 45, paddingLeft: 2 }}>
                      <TableHeaderColumnContent
                        column="netScore"
                        text="Net"
                        currentSortColumn={currentSortColumn}
                        currentSortIsAsc={currentSortIsAsc}
                        requestSort={requestSort}
                      />
                    </TableCell>
                    <TableCell className="scoreColumn column sortable" title="Putts" style={{ width: 45, paddingLeft: 2 }}>
                      <TableHeaderColumnContent
                        column="putts"
                        text="Putts"
                        currentSortColumn={currentSortColumn}
                        currentSortIsAsc={currentSortIsAsc}
                        requestSort={requestSort}
                      />
                    </TableCell>
                    <TableCell className="scoreColumn column sortable" title="Fairways" style={{ width: 45, paddingLeft: 2 }}>
                      <TableHeaderColumnContent
                        column="fairwaysInRegulation"
                        text="FiR"
                        currentSortColumn={currentSortColumn}
                        currentSortIsAsc={currentSortIsAsc}
                        requestSort={requestSort}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {/* stripedRows={true}  */}
                  {game.scores.map((t, x) => (
                    <TableRow key={t.turtle.id}>
                      <TableCell className="column turtleColumn" style={{ width: 195 }}>
                        {x + 1}.
                        <Link to={RoutingService.turtleSeason(t.turtle, game!.season)}>
                          <span className="name">{t.turtle.fullNameReverse}</span>
                        </Link>
                      </TableCell>
                      <TableCell className="column scoreColumn" style={{ width: 45 }}>
                        <Link to={RoutingService.scoreDetails(t.turtle, t.id)}>{t.grossScore}</Link>
                      </TableCell>
                      <TableCell className="column scoreColumn" style={{ width: 45 }}>
                        {t.netScore}
                      </TableCell>
                      <TableCell className="column scoreColumn" style={{ width: 45 }}>
                        {t.putts}
                      </TableCell>
                      <TableCell className="column scoreColumn" style={{ width: 45 }}>
                        {t.fairwaysInRegulation}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className="messageContainer" ref={messagesRef}>
              <h4>Messages</h4>
              <ul>
                {game!.messages.map((m, index, arr) => (
                  <li key={m.id} className={index > 0 && m.priority != arr[index-1].priority ? 'new-priority' : ''}>{m.messageText}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* TODO:DF  */}
        {/* <BottomNavigation className="bottomNav" selectedIndex={selectedNavigationIndex} style={{position: "fixed", left: 0, bottom: 0}} >
                <BottomNavigationItem
                    onClick={goToSummary}
                    label="Summary"
                    icon={<InfoIcon />}                    
                />
                <BottomNavigationItem
                    onClick={goToScores}
                    label="Scores"
                    icon={<ScoreIcon />}                    
                />
                <BottomNavigationItem
                    onClick={goToMessages}
                    label="Messages"
                    icon={<MessageIcon />}                    
                />
            </BottomNavigation> */}
      </div>
    );
  };

  return <div className="GameDetailsPage">{isLoading ? <AppLoading /> : renderPage()}</div>;
};
