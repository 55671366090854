import * as React from "react";
import { Link, useParams } from "react-router-dom";
import CourseService from "../services/CourseService";
import { ICourseDetails, ICourse } from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { AppLoading } from "../components/common/AppLoading";
import "../css/CourseDetailsPage.scss";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

export const CourseDetailsPage = () => {
  const [course, setCourse] = useState<ICourseDetails>();
  const [loading, setLoading] = useState<boolean>(true);
  let { id } = useParams();

  useEffect(() => {
    document.title = `Course Details`;
    if (!id) {
      console.error("No id provided");
      return;
    }
    CourseService.getDetails(+id).then((data) => {
      setCourse(data);
      setLoading(false);

      document.title = `Course: ${data.name}`;
    });
  }, [id]);

  const createDirection = (course: ICourse): string => {
    let from = `${course.address} ${course.city} ${course.postalCode}`.replace(/ /g, "+");
    return `https://www.google.com/maps/dir/?api=1&origin=${from}`;
  };

  const renderCourseTees = (course: ICourseDetails) => {
    return (
      <div className="tees">
        <h4>Tees</h4>
        <Table size="small" className="desktop">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="name column" title="Name">
                Name
              </TableCell>
              <TableCell className="yardage column" title="Total Distance">
                Yardage
              </TableCell>
              <TableCell className="rating column" title="Handicap Rating">
                Rating
              </TableCell>
              <TableCell className="slope column" title="Handicap Slope">
                Slope
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* TODO:DF Have to add the checkbox cell */}
            {course.tees.map((t) => (
              <TableRow key={t.id}>
                <TableCell className="name column">{t.name}</TableCell>
                <TableCell className="yardage column">{t.yardage}</TableCell>
                <TableCell className="rating column">{t.rating}</TableCell>
                <TableCell className="slope column">{t.slope}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderCourseHistory = (course: ICourseDetails) => {
    const columnWidth = 90;
    return (
      <div className="history">
        <h4>History</h4>
        <Table size="small" className="desktop">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow className="superHeader">
              <TableCell colSpan={2} className="season-group column" title="Season Details"></TableCell>
              <TableCell colSpan={3} className="gross-group column" title="Gross Score Details">
                Gross
              </TableCell>
              <TableCell colSpan={2} className="net-group column" title="Net Score Details">
                Net*
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: columnWidth }} className="season column" title="Season Played">
                Year
              </TableCell>
              <TableCell style={{ width: columnWidth }} className="dayNumber column" title="Day Played">
                Day
              </TableCell>
              <TableCell
                style={{ width: columnWidth }}
                className="lowScore gross-group column"
                title="Lowest Score of the Day"
              >
                Low
              </TableCell>
              <TableCell style={{ width: columnWidth }} className="highScore column" title="Highest Score of the Day">
                High
              </TableCell>
              <TableCell
                style={{ width: columnWidth }}
                className="averageScore column"
                title="Average Score of the Day"
              >
                Avg
              </TableCell>
              <TableCell
                style={{ width: columnWidth }}
                className="lowScore net-group column"
                title="Lowest Net Score of the Day"
              >
                Low
              </TableCell>
              <TableCell
                style={{ width: columnWidth }}
                className="highScore column"
                title="Highest Net Score of the Day"
              >
                High
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {course.games.map((g) => (
              <TableRow key={g.id} className="history--body">
                <TableCell style={{ width: columnWidth }} className="season column">
                  <Link to={RoutingService.seasonDetails(g.season)}>{g.season.year}</Link>
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="dayNumber column">
                  <Link to={RoutingService.gameDetailsByDayNumber(g.dayNumber, g.season)}>{g.dayNumber}</Link>
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="lowScore gross-group column">
                  {g.lowGrossScore && (
                    <Link to={RoutingService.scoreDetails(g.lowGrossScore.turtle, g.lowGrossScore.id)}>
                      {g.lowGrossScore.grossScore}
                    </Link>
                  )}
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="highScore column">
                  {g.highGrossScore && (
                    <Link to={RoutingService.scoreDetails(g.highGrossScore.turtle, g.highGrossScore.id)}>
                      {g.highGrossScore.grossScore}
                    </Link>
                  )}
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="averageScore column">
                  {g.averageGross}
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="lowScore net-group column">
                  {g.lowNetScore && g.season.year >= 2016 && (
                    <Link to={RoutingService.scoreDetails(g.lowNetScore.turtle, g.lowNetScore.id)}>
                      {g.lowNetScore.grossScore}
                    </Link>
                  )}
                </TableCell>
                <TableCell style={{ width: columnWidth }} className="highScore column">
                  {g.highNetScore && g.season.year >= 2016 && (
                    <Link to={RoutingService.scoreDetails(g.highNetScore.turtle, g.highNetScore.id)}>
                      {g.highNetScore.grossScore}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="legend">
          * Net records are only shown for 2016 and onwards, when we switched to a handicap system.
        </div>
      </div>
    );
  };

  const renderCourseProperties = (course: ICourseDetails) => {
    return (
      <div className="properties">
        <div className="properties--property">
          <div className="properties--property--label">Address</div>
          <div className="properties--property--data">
            <a href={createDirection(course)} target="_blank">
              {course.address}
            </a>
          </div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">City</div>
          <div className="properties--property--data">{course.city}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">State</div>
          <div className="properties--property--data">{course.province}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Postal Code</div>
          <div className="properties--property--data">{course.postalCode}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Country</div>
          <div className="properties--property--data">{course.country}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Phone</div>
          <div className="properties--property--data">{course.phone}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Email</div>
          <div className="properties--property--data">{course.email}</div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Web Site</div>
          <div className="properties--property--data">
            {course.url && (
              <a href={course.url} target="_blank">
                {course.url}
              </a>
            )}
          </div>
        </div>
        <div className="properties--property">
          <div className="properties--property--label">Notes</div>
          <div className="properties--property--data">{course.notes}</div>
        </div>
      </div>
    );
  };

  if (loading || !course) return <AppLoading />;
  return (
    <div className="course-details">
      <h4 className="header">
        <span className="course">Course:</span>
        <span>{course.name}</span>
      </h4>
      {renderCourseProperties(course)}
      {renderCourseTees(course)}
      {renderCourseHistory(course)}
    </div>
  );
};
