import { useEffect, useState } from "react";
import * as Models from "../../models/models";
import dayjs, { Dayjs } from "dayjs";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { IGameWithCourseSummaryAdmin } from "../../models/models";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CourseService from "../../services/CourseService";

interface Props {
  game: IGameWithCourseSummaryAdmin;
  courses: Models.ICourseSummary[];
  onTeeChange: (tee: Models.ITeeSummary, course: Models.ICourseSummary) => void;
  onDateChange: (date?: Date) => void;
}

export const GameEntry = (props: Props) => {
  const [date, setDate] = useState<Dayjs | null>(dayjs(props.game.date));
  const [course, setCourse] = useState<Models.ICourseSummary>();
  const [tees, setTees] = useState<Models.ITeeSummary[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (props.game.tee) {
      setCourse(props.courses.find((c) => c.id === props.game.tee?.course.id));
    } else {
      setIsEditing(true);
    }
  }, [props.game.tee]);

  useEffect(() => {
    if (!course) return;
    setIsLoading(true);
    CourseService.getTees(course?.id).then((tees) => {
      setTees(tees!);
      setIsLoading(false);
    });
  }, [course]);

  useEffect(() => {
    setDate(dayjs(props.game.date));
  }, [props.game.date]);
  
  const onCourseChange = (id: number) => {
    setCourse(props.courses.find((c) => c.id === id));
  };

  const onTeeChange = (id: number) => {
    const tee = tees?.find((c) => c.id === id)!;
    props.onTeeChange(tee, course!);
  };

  const onDateChange = (value: Dayjs | null) => {
    props.onDateChange(value ? value.toDate() : undefined);
  };
  
  return (
    <div>
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          {isEditing && (
            <div className="game-entry-container">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Game Date"
                  value={date}
                  onChange={onDateChange}  
                />
              </LocalizationProvider>
              <FormControl sx={{ m: 1, marginLeft: 0 }} size="small">
                <InputLabel id="course">Course</InputLabel>
                <Select
                  labelId="course"
                  label="Course"
                  placeholder="Select Course"
                  value={course?.id || ""}
                  onChange={(e) => onCourseChange(+e.target.value)}
                  style={{ width: 220 }}
                  autoWidth={false}
                >
                  {props.courses.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, marginLeft: 0 }} size="small">
                <InputLabel id="tee">Tee</InputLabel>
                <Select
                  labelId="tee"
                  label="Tee"
                  placeholder="Select Tee"
                  value={props.game.tee?.id || ""}
                  onChange={(e) => onTeeChange(+e.target.value)}
                  style={{ width: 220 }}
                  autoWidth={false}
                >
                  {tees &&
                    tees.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!isEditing && (
            <div className="game-entry-container">
              {/* <Typography variant="h5" component="div">
              </Typography> */}
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {date?.format("ddd MMM D h:mm a")}
              </Typography>
              <Typography variant="body2">
                {props.game.tee?.name} at {course?.name}
              </Typography>
              <Button size="small" onClick={() => setIsEditing(true)}>Edit</Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
