import * as Models from "../models/models";

export class RoutingService {
    private static cleanUp = (text: string): string => {
        if (!text)
            return text;
        return text.replace(/ /g, "_");
    }
    public static home = "/";
    public static login = "/login";
    public static register = "/register";
    public static scoreDetails = (turtle: Models.ITurtle, id: number) => { return `/score/${turtle ? RoutingService.cleanUp(turtle.fullName) : "details"}/${id}`; }
    public static gameDetails = (game: Models.IGameSummary, season?: Models.ISeasonSummary) => { return RoutingService.gameDetailsByDayNumber(game.dayNumber, season); }
    public static gameDetailsByDayNumber = (dayNumber: number, season?: Models.ISeasonSummary) => {
        return `/game/${season ? season.year : "details"}/Day-${dayNumber}/`;
    }

    public static seasonDetails(seasonSummary: Models.ISeasonSummary): string { return `/season/${seasonSummary.year}`; }

    public static turtle(turtle: Models.ITurtle): string { return `/turtle/${RoutingService.cleanUp(turtle.fullName)}/${turtle.id}`; }
    public static turtleSeason(turtle: Models.ITurtle, season: Models.ISeasonSummary): string { return `/turtle/${RoutingService.cleanUp(turtle.fullName)}/${turtle.id}/${season ? season.year : undefined}`; }

    public static courses(): string { return `/courses/`; }

    public static course(course: Models.ICourseSummary): string { return `/course/${RoutingService.cleanUp(course.name)}/${course.id}`; }

    static trophyDetails(trophyAward: Models.ITrophyAward): string { return `/trophy/${RoutingService.cleanUp(trophyAward.trophy.name)}/${trophyAward.trophy.id}`; }

    static admin = {
        turtle(turtle: Models.ITurtleDetails):string { return `/admin/players/${turtle.id}/${RoutingService.cleanUp(turtle.fullName)}`;}
    }
}