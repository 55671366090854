export class Observable<T> {
  //The enable/disable is a bit weird but we don't always have access to the method we subscribed with, but we don't want it knowing
  //about a refresh either (useCallback makes things messy as well)
  protected observers: Array<{ callback: (data: T) => void; key?: string; enabled: boolean }>;

  constructor() {
    this.observers = [];
  }

  subscribe(f: (data: T) => void, key?: string) {
    this.observers.push({ callback: f, key, enabled: true });
  }

  enableSubscription = (key: string) => {
    if (key) {
      this.observers.some((c) => {
        if (c.key && c.key === key) {
          c.enabled = true;
          return true;
        }
        return false;
      });
    }
  };

  disableSubscription = (key: string) => {
    if (key) {
      this.observers.some((c) => {
        if (c.key && c.key === key) {
          c.enabled = false;
          return true;
        }
        return false;
      });
    }
  };

  unsubscribeKey(key: string) {
    if (key) {
      this.observers = this.observers.filter((subscriber) => !subscriber.key || subscriber.key !== key);
    }
  }

  unsubscribe(f: (data: T) => void) {
    this.observers = this.observers.filter((subscriber) => subscriber.callback !== f);
  }
}

export class NotifiableObservable<T> extends Observable<T> {
  notify(data: T) {
    this.observers.filter((c) => c.enabled).forEach((observer) => observer.callback(data));
  }
}